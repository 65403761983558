import { Button } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { ButtonSave } from "../../../ui/Button";
import EmailComponents from "./ComposeEmail/EmailComponents";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../../_helpers";

const ComposeEmail = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [isUsers, isSetUsers] = useState<any>(true);
    const [users, setUsers] = useState<any>();
    const [loader, setLoader] = useState(false)
    const [subject_text, setSubject_text] = useState('Invoice from Niobi');
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [Status, setStatus] = useState('')
    const [selectedList, setSelectedList] = useState<string[]>([email])
    const [body_text, setBody_text] = useState(`Hi ${name},\nPlease find the invoice attached as discussed.\nKind regards,\nFaith`)

    const [moniteId, setMoniteId] = useState("")

    console.log(email, name, selectedList, users?.data, "emailemail");
    useEffect(() => {
        if (isUsers && getUser) {
            isSetUsers(false);
            setUsers(getUser);
        }
    });
    useEffect(() => {
        const popup: any = searchParams.get('invoiceId');
        setMoniteId(popup)
    }, [searchParams])


    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    useEffect(() => {
        const id: any = searchParams.get('invoiceId');
        const email: any = searchParams.get('email');
        const name: any = searchParams.get('name');
        const Status: any = searchParams.get("status")
        setId(id)
        setEmail(email)
        setName(name)
        setStatus(Status)
    }, [searchParams]);
    console.log(selectedList, body_text, subject_text, "selectedList");
    useEffect(() => {
        setSelectedList([email])
        setBody_text(`Hi ${name},\nPlease find the attached invoice. Kindly click on the button to proceed with payment.\nThank you for your business.\n${!!users ? (!!users?.data.name ? users?.data?.name : '') : ''}`)
    }, [email, name, users])

    const SubmitEmail = () => {
        if (selectedList.length == 0) {

        } else {
            const body = {
                "monite_invoice_id": moniteId,
                "body_text": body_text,
                "subject_text": subject_text,
                "emails": selectedList
            }
            // console.log(body,"safdafssaffff");
            setLoader(true);

            dispatch(
                paymentJourneyActions.sendInvoiceActive(
                    body,
                    id,
                    Status
                ) as any
            );
            setTimeout(() => {
                navigate("/invoice")
            }, 3000);
        }
    }

    console.log("moniteId =>", moniteId);

    return (
        <>
            <ThemeProvider theme={theme}>
                <PageHeader
                    title="Compose email"
                    extra={
                        <ButtonSave onClick={SubmitEmail} text={'Issue and send'} loader={loader} style={{}}>

                        </ButtonSave>
                    }

                />
                <EmailComponents email={email} name={name} moniteId={moniteId} body_text={body_text} setBody_text={setBody_text} subject_text={subject_text} setSubject_text={setSubject_text} setSelectedList={setSelectedList} selectedList={selectedList} />
            </ThemeProvider>
        </>
    )
}
export default ComposeEmail;