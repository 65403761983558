import { Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './connectTransactions.module.scss';
import TransactinTable from './connectTransactionTable';
import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

const ConnectTransaction = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionId, setTransactionId] = useState([])
  const [transactionCurrency, setTransactionCurrency] = useState([])
  const [transactionAmountdata, setTransactionAmountdata] = useState([])

const theme = createTheme({
        status: {
          danger: "#025041",
        },
 });

useEffect(() => {
    const id: any = searchParams.get('id');
    const currency: any = searchParams.get('currency');
    const transactionAmount: any = searchParams.get('transactionAmount');
    setTransactionId(id);
    setTransactionCurrency(currency);
    setTransactionAmountdata(transactionAmount)
}, [searchParams]);


    return (
        <>
            <ThemeProvider theme={theme}>
                <>
                <Box className={styles.ContenarInvoice}>
                    <Box>
                        <Typography className={styles.typo_heding}>Connect Payment with Invoice</Typography>
                    </Box>
                </Box>
                <Box className={styles.ContenarTebal}>
                  <TransactinTable transactionData={{ transactionId, transactionCurrency, transactionAmountdata }} />
                </Box>
                </>
            </ThemeProvider>
        </>
    )
}
export default ConnectTransaction;