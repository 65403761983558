import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import styles from "./customers_details.module.scss";
import { Button } from "react-bootstrap";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import Setp1 from "./setp1";
import Setp2 from "./setp2";
import { TransactionDetails } from "../../../Balance/components/TransactionDetails/TransactionDetails";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from "../../../../../../_actions/counterparts.actions";
import { selectAllCounterpartsListData } from "../../../../../../_reducers/counterparts.reducer";
import Invoices_Statements from "../invoices_Statements/invoices_Statements";
import Setp4 from "./setp4";
import { SkeletonDashboardV3 } from "../../../Skeleton/Skeleton";
import { dashboardActions } from "../../../../../../_actions";
import { selectAllData } from "../../../../../../_reducers/dashboard.reducer";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Customers_details = ({ data, id }: any) => {
  const [value, setValue] = React.useState(0);
  const [render_two, setRender_two] = React.useState(true);
  const [showTransMoodal, setshowTransMoodal] = React.useState(false);
  const [moreData, setMoreData] = React.useState({});
  const [customarRedusar, setCustomarRedusar] = React.useState(false);
  const [Detels, setDetels] = React.useState<any>();
  const [DataArraylog, setDataArraylog] = React.useState<any>([]);
  const [currencyAndMethodData, setCurrencyAndMethodData] = React.useState([]);
  const [selectCurrencyPopup, setSelectCurrencyPopup] = React.useState<any>('KES')

  const[loader,setLoader]=React.useState(false)
  const dispatch = useDispatch();
  const dataReduser = useSelector((state) => selectAllData(state));

  console.log(Detels, DataArraylog, "DetelsDetels");
  React.useEffect(() => {
    dispatch(dashboardActions.getDashboardCurrencyData() as any)
  }, [dispatch])

  React.useEffect(() => {
    if (dataReduser && dataReduser.GET_DASHBOARD_CURRENCY_DATA_SUCCESS !== undefined) {
      setCurrencyAndMethodData(dataReduser.GET_DASHBOARD_CURRENCY_DATA_SUCCESS.data);
    }
  }, [dataReduser.GET_DASHBOARD_CURRENCY_DATA_SUCCESS])
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const counterpartData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!!id && render_two) {
      dispatch(
        counterpartsActions.getCustomerDetailsId(id, setCustomarRedusar,selectCurrencyPopup,setLoader) as any
      );
      // setLoader(true)
      setRender_two(false);
    }
  }, [id]);

  React.useEffect(() => {
    if (
      customarRedusar &&
      counterpartData?.GETCUSTOMER_DETAILS_ID_SUCCESS !== undefined
    ) {
      setCustomarRedusar(false);
      setDetels(counterpartData?.GETCUSTOMER_DETAILS_ID_SUCCESS);

      setDataArraylog(
        counterpartData?.GETCUSTOMER_DETAILS_ID_SUCCESS?.customer?.invoices
      );
      // setTimeout(()=>{
      //   setLoader(false)
      // },4000)
    }
  }, [counterpartData, customarRedusar]);
  const theme = createTheme({
    status: {
      danger: "#025041",
    },
  });
  console.log(!!Detels?.csv_payment_request, !!Detels?.csv_payment, "!!Detels.csv_payment_request");

  return (
    <>
      <Grid container className={styles.Customers_details_container}>
        <Grid item md={12} xs={12}>
          <Box className={styles.Customers_details_box}>
            <Button
              onClick={() => {
                navigate("/customers");
              }}
            >
              <BackIcon />
             Go Back
            </Button>
            <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="selectBox"
                  value={selectCurrencyPopup}
                  // sx={{
                  //   p: {
                  //     display: "none"
                  //   }
                  // }}
                  onChange={(e: any) => {
                    setSelectCurrencyPopup(e.target.value)
                    dispatch(
                      counterpartsActions.getCustomerDetailsId(id, setCustomarRedusar,e.target.value,setLoader) as any
                    );
                  }}
                >
                  {Array.isArray(currencyAndMethodData) && currencyAndMethodData?.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        value={item.currency}
                        key={index}>
                        <Box className="MenuItemBox" >
                          <img
                            loading="lazy"
                            width="25"
                            src={item.flag}
                            alt=""
                          />
                          <p>
                            {item.currency}
                          </p>
                        </Box>
                      </MenuItem>
                    )
                  })}
                </Select>
          </Box>
          <Box className={styles.calssName_heding}>
            <Typography>
              {/* {data.name} */}
              {Detels?.customer?.name}
            </Typography>

          <Typography>
            {Detels?.customer?.customer_identifier}
          </Typography>
          </Box>
          {showTransMoodal && (
            <TransactionDetails
              show={showTransMoodal}
              onClose={() => {
                setshowTransMoodal(false);
                setMoreData("");
              }}
              data={moreData}
            // onSave={(el: boolean) => {
            //   setShowTopup(false);
            //   setTopupMethod(el);
            //   setTopup(true);
            //   setShowBalanceGrid(false);
            // }}
            />
          )}
        </Grid>
    
        <Grid item md={12} xs={12}>
        <SkeletonDashboardV3
            width={"100%"}
            height={'450px'}
            loader={loader}
            element={
          <Box className={styles.Box_Recent_activity}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <ThemeProvider theme={theme}>
                <Box className={styles.hedaderBox}>
                  <Tabs
                    sx={{
                      // height:"100%",
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#025041",
                        height: "0.5px",
                      },
                      "& .MuiTab-root.Mui-selected": {
                        color: "#025041",
                        fontSize: 14,
                        textTransform: "capitalize",
                        borderBottom:"1px solid #025041"
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      className={styles.tab_name}
                      label="Profile"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className={styles.tab_name}
                      label="Payments"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className={styles.tab_name}
                      label="Statements"
                      {...a11yProps(2)}
                    />
                    <Tab
                      className={styles.tab_name}
                      label="Payment request"
                      {...a11yProps(3)}
                    />
                  </Tabs>
                  {value == 2 || value == 1 || value == 3 ?
                    <Button className={styles.DownloadButton} href={!!Detels?.csv_payment && value == 1 ? Detels?.csv_payment : value == 2 && !!Detels?.csv_statement ? Detels?.csv_statement : value == 3 && !!Detels?.csv_payment_request ? Detels?.csv_payment_request : ""} disabled={!!Detels?.csv_payment && value == 1 ? false : value == 2 && !!Detels?.csv_statement ? false : value == 3 && !!Detels?.csv_payment_request ? false : true}>
                      <DownloadIcon />
                      <span style={{ paddingLeft: "10px" }}></span>Download
                    </Button> : ""}
                </Box>
              </ThemeProvider>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Setp1
                DataArraylog={DataArraylog}
                Detels={Detels}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Setp2
                data={Detels?.payments}
                onSelect={(data: any) => {
                  setshowTransMoodal(true);
                  setMoreData(data);
                }}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Invoices_Statements datatabal={Detels?.statements} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Setp4 data={Detels} />
            </CustomTabPanel>
          </Box>}>
          </SkeletonDashboardV3>
        </Grid>
      </Grid>
    </>
  );
};
export default Customers_details;
const BackIcon = () => (
  <svg
    width="17"
    height="8"
    viewBox="0 0 17 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H17V3.5H1V4.5Z"
      fill="#1D1D1D"
    />
  </svg>
);
const DownloadIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3688 13.5557C10.3842 13.5753 10.4038 13.5911 10.4262 13.602C10.4486 13.6129 10.4731 13.6186 10.498 13.6186C10.5229 13.6186 10.5475 13.6129 10.5699 13.602C10.5923 13.5911 10.6119 13.5753 10.6272 13.5557L12.9241 10.6497C13.0082 10.5431 12.9323 10.3852 12.7949 10.3852H11.2753V3.44531C11.2753 3.35508 11.2015 3.28125 11.1112 3.28125H9.88076C9.79053 3.28125 9.7167 3.35508 9.7167 3.44531V10.3831H8.20117C8.06377 10.3831 7.98789 10.541 8.07197 10.6477L10.3688 13.5557ZM18.0039 12.8379H16.7734C16.6832 12.8379 16.6094 12.9117 16.6094 13.002V16.1602H4.38672V13.002C4.38672 12.9117 4.31289 12.8379 4.22266 12.8379H2.99219C2.90195 12.8379 2.82812 12.9117 2.82812 13.002V17.0625C2.82812 17.4255 3.12139 17.7188 3.48438 17.7188H17.5117C17.8747 17.7188 18.168 17.4255 18.168 17.0625V13.002C18.168 12.9117 18.0941 12.8379 18.0039 12.8379Z"
      fill="#025041"
    />
  </svg>
);
