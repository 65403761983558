import { Button } from 'react-bootstrap';
import ProductTable from './components/productTable/productTable';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const Products = () => {
    const navigate = useNavigate();
    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });
   
    return (
        <>
            <div className="balance">
                <div className="balance__header">
                    <div className="balance__header__name">Products</div>
                    <div>
                        <>
                            <Button
                                className="balance__save-btn"
                                onClick={() => {
                                    navigate("/create-product")
                                }}
                            >
                                Create New
                            </Button>
                        </>
                    </div>
                </div>
                <ThemeProvider theme={theme}>
                        <ProductTable />
                </ThemeProvider>
            </div>
        </>
    )
}
export default Products;