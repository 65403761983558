import { Avatar, Box, IconButton, Menu, Radio, Typography } from '@mui/material';
import './Document_details.scss';
import React, { useEffect } from 'react';
import moment from "moment";
import CopyText from './copyText';
import ShareIcon from '@mui/icons-material/Share';
import { EmailShareButton, WhatsappShareButton } from 'react-share';
import { Button, Tooltip } from 'react-bootstrap';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Chackbox = () => (
    <svg width="18" height="17" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.5" cy="7" r="6" stroke="#025041" />
        <circle cx="6.5" cy="7" r="2.5" fill="#025041" />
    </svg>
)
const RadioIcon = () => (
    <svg width="18" height="17" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.5" cy="7" r="6" stroke="#D9D9D9" />
    </svg>
)
const Document_details = (props) => {
    // console.log(props?.data?.data?.customer?.name, "propsprops");
    const [selectedValue, setSelectedValue] = React.useState('b');
    const [shareLink, setSreeaLink] = React.useState('')
    const [anchorElWhatsApp, setAnchorElWhatsApp] = React.useState(null);
    const openWhatsApp = Boolean(anchorElWhatsApp)

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleClick = (event) => {
        setAnchorElWhatsApp(event.currentTarget);
    };
    const handleClose_twoo = () => {
        setAnchorElWhatsApp(null);
    };
    useEffect(() => {
        var trans_cat = [];

        if (!!props) {
            console.log(props, "propsprops");
            if (props?.data?.payable) {

            }

            // for (let index = 0; index < element.unified_transaction_categories.length; index++) {
            // const records = element.unified_transaction_categories[index];

            // }
        }
    }, [props])

    console.log("invoice detail props =>", props);

    return (
        <>

            <div className='main_dic_contenar'>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElWhatsApp}
                    open={openWhatsApp}
                    onClose={handleClose_twoo}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ "& .MuiMenu-list": { padding: "0px" } }}
                >
                    <div style={{ padding: "5px" }}>
                        <div style={{ height: "27px" }}>
                            <WhatsappShareButton
                                url={shareLink}
                                onClick={handleClose_twoo}
                            >
                                Send via WhatsApp
                            </WhatsappShareButton>
                        </div>
                        <div style={{ height: "27px" }}>
                            <EmailShareButton
                                url={shareLink}
                                body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${shareLink}${'\n'}`}
                            >
                                Send via Email

                            </EmailShareButton>

                            {/* <a
                            href={`mailto:${email}?subject=${encodeURIComponent(shareLink)}&body=${encodeURIComponent(body)}`}
                        > Send via Email
                        </a>
                        <EmailShareButton shareLink={shareLink} /> */}

                        </div>
                    </div>
                </Menu>
             
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Customer details
                        </Typography>
                        <div className='main_div_divfooter'>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Customer</Typography>
                                <Box className="box_avatarText">
                                    {/* <Avatar className='avatarStyles' src='' /> */}
                                    <Typography style={{ padding: "0px" }} className='two_text_conteanana'>{props?.data?.data?.customer?.name}</Typography>
                                </Box>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Contact Number</Typography>
                                <Box className="box_avatarText">
                                    {/* <Avatar className='avatarStyles' src='' /> */}
                                    <Typography style={{ padding: "0px" }} className='two_text_conteanana'>{props?.data?.data?.customer?.contact_phone}</Typography>
                                </Box>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Invoice Number</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.invoice_no}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Message</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.memo}</Typography>
                            </div>


                            {/* <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Submitted by</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.payable?.submitter?.name}</Typography>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Line items
                        </Typography>
                        <div className='main_lineitem'>
                            <div className='main_div_other_div'>
                                <div className='main_div_name' style={{ width: "26%" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Name</Typography>
                                    </div>
                                </div>
                                <div className='main_div_name' style={{ width: "20%", textAlign: "center" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Unit price</Typography>
                                    </div>
                                </div>
                                <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Quantity</Typography>
                                    </div>
                                </div>
                                <div className='main_div_name' style={{ width: "20%" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Vat</Typography>
                                    </div>
                                </div>
                                <div className='main_div_name' style={{ width: "20%" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Description</Typography>
                                    </div>
                                </div>


                            </div>

                            {props?.data?.data?.items.map((data, index) => {
                                {
                                    console.log((((data.total / 100) * data.quantity) - (data.subtotal / 100)), data.total, data.subtotal, "sfszffzsffff");
                                }
                                return (
                                    <div className='main_div_other_div'>
                                        <div className='main_div_name' style={{ width: "26%" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{data.title}</Typography>
                                            </div>
                                        </div>
                                        <div className='main_div_name' style={{ width: "20%", textAlign: "center" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{props?.data?.data?.currency} {data.price}</Typography>
                                            </div>
                                        </div>
                                        <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{data.quantity}</Typography>
                                            </div>
                                        </div>
                                        <div className='main_div_name' style={{ width: "20%" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{data.tax}%</Typography>
                                            </div>
                                        </div>
                                        <div className='main_div_name' style={{ width: "20%" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{data.description}</Typography>
                                            </div>
                                        </div>


                                    </div>
                                )
                            })}

                        </div>
                    </div>

                </div>
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Payment details
                        </Typography>
                        <div className='main_div_divfooter'>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Payment status</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.status == "paid" ? "Paid" : props?.data?.data?.status == "partially_paid" ? 'Partially Paid':props?.data?.data?.status=="canceled"?"" : "Unpaid"}</Typography>
                            </div>
                            {props?.data?.data?.status == "partially_paid" ?
                                <div className='row_text_conteann'>
                                    <Typography className='fast_text_lineen'>Paid Amount</Typography>
                                    <Typography className='two_text_conteanana'>{props?.data?.data?.currency} {props?.data?.data?.paid_amount}</Typography>
                                </div> : ""}
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Due date</Typography>
                                <Typography className='two_text_conteanana'>{moment(props?.data?.data?.due_date).format('YYYY-MMMM-DD')}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Payment Connect</Typography>
                                <Typography className='two_text_conteanana'>
                                    {!!props?.data?.data?.payment_link?.link ? props?.data?.data?.payment_link?.link : "-"}
                                    {!!props?.data?.data?.payment_link?.link ?
                                        <CopyToClipboard text={props?.data?.data?.payment_link?.link}>
                                            <Button data-tooltip-id="my-tooltip-click" className='button_ContentCopyIcon'>
                                                <ContentCopyIcon />
                                            </Button>
                                        </CopyToClipboard>
                                        : ""}
                                    {/* {props?.data?.data?.payment_link?.link} */}
                                    {!!props?.data?.data?.payment_link?.link ?
                                        <IconButton
                                            onClick={(e) => {
                                                setSreeaLink(props?.data?.data?.payment_link?.link ? props?.data?.data?.payment_link?.link : "")
                                                handleClick(e)
                                            }}
                                        ><ShareIcon style={{ color: "#000" }} /></IconButton> : ""}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Total
                        </Typography>
                        <div className='main_div_divfooter'>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Subtotal</Typography>
                                <Typography className='threed_test_data'>{props?.data?.data?.currency} {props?.data?.data?.sub_total}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>VAT</Typography>
                                <Typography className='two_text_conteanana'> {props?.data?.payable?.vat == 'Exempt' ? 'Exempt' : `${props?.data?.data?.currency} ${props?.data?.data?.tax ? props?.data?.data?.tax : 0}`}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Discount</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.currency} {props?.data?.data?.discount ? props?.data?.data?.discount : 0}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Total</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.currency} {props?.data?.data?.total}</Typography>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Bill lifecycle
                        </Typography>

                        <div className='Bill_lifecyclediv'>
                            {props?.data?.data?.creator?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>Created by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.creator?.name}</Typography></div>
                                        <div className='Submitted_datedev'><Typography className='TypographySubmittedheding'>Creation date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.created_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.submitter?.name || !!props?.data?.data?.canceller?.name || !!props?.data?.data?.rejector?.name || !!props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "29px" }}></div> : ""}
                                </>
                                : ""}
                            {props?.data?.data?.submitter?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Submitted by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.submitter?.name}</Typography></div>
                                        <div className='Submitted_datedev'><Typography className='TypographySubmittedheding'>Submitted date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.submitted_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.canceller?.name || !!props?.data?.data?.rejector?.name || !!props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "58px" }}></div> : ""}</>
                                : ""}
                            {props?.data?.data?.canceller?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Cancelled by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.payable?.canceller?.name}</Typography></div>
                                        <div className='Submitted_datedev'>
                                            <Typography className='TypographySubmittedheding'>Cancellation date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.payable?.cancelled_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.rejector?.name || !!props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "58px" }}></div> : ""}</> : ""}
                            {props?.data?.data?.rejector?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Rejected by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.rejector?.name}</Typography></div>
                                        <div className='Submitted_datedev'>
                                            <Typography className='TypographySubmittedheding'>Rejection date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.rejected_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "94px" }}></div> : ""}</> : ""}

                            {props?.data?.data?.approver?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Approved by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.approver?.name}</Typography></div>
                                        <div className='Submitted_datedev'>
                                            <Typography className='TypographySubmittedheding'>Approval date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.approved_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "94px" }}></div> : ""}</> : ""}
                            {props?.data?.data?.payer?.name ?
                                <div className='conteanBill_lifecycl'>
                                    <div className='clsTypographydiv'>
                                        <div className='Typography_icon'>
                                            <DotIcon />
                                            <Typography className='clsTypographyheding'>
                                                Paid by</Typography>
                                        </div>
                                        <Typography className='clsTypographyname'>{props?.data?.data?.payer?.name}</Typography></div>
                                    <div className='Submitted_datedev'>
                                        <Typography className='TypographySubmittedheding'>Payout date</Typography>
                                        <Typography className='dateTypography'>{moment(props?.data?.data?.paid_at).format('YYYY-MMMM-DD')}</Typography></div>
                                </div> : ""}
                        </div>
                    </div>
                </div> */}

            </div>
            <Tooltip
                id="my-tooltip-click"
                content="link has been copied"
                events={['click']}
            />
        </>
    )
}
export default Document_details;

const DotIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 5 5" fill="none">
        <circle cx="2.5" cy="2.5" r="2.5" fill="#025141" />
    </svg>
)