import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import styles from '../Pay.module.scss';
import Detailspayment from './detailspayment';
import Setp2 from './setp2';
import { TransactionDetails } from './TransactionDetails/TransactionDetails'
import { paymentJourneyActions } from "../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import { selectPaymentJourneyData } from "../../../../../_reducers/paymentJourney.reducer";
import { Button } from 'react-bootstrap';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import countryList from 'react-select-country-list';

interface Props {
    data: any;
    contryCode: any;
    shortcode: any;
}

const Setp1 = ({ data, shortcode, contryCode }: Props) => {
    const [setp_data, setSetp_data] = useState(1)
    const [success_data, setSuccess_data] = useState("")
    const [dataSuccess, setDataSuccess] = useState("")
    const [payReqrender, setpayReqrender] = useState(false);
    const [payReqSuccrender, setpayReqSuccrender] = useState(false);
    const [payReqResrender, setpayReqResrender] = useState(false);
    const [paymentStep, setpaymentStep] = useState(false);
    const [checkTransapi, setcheckTransapi] = useState(false);
    const [trans, settrans] = useState<any>();
    const [getTrans, setGettrans] = useState(true);
    const [amount, setAmount] = useState(data?.amount == null ? 0 : data?.amount)
    const [contryCode2, setcountry_code2] = useState("KE")
    const [currency, setCurrency] = useState(data?.currency == '' ? "" : data?.currency)
    const [listData, setListdata] = useState([
        {
            "id": 250,
            "company_id": 272,
            "receiver_id": null,
            "invoice_name": "Rwanda",
            "logo": "https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Harvey%20Mike-INVOICE.jpeg",
            "invoice_no": "INV1319802",
            "currency": "RWF",
            "issued_at": null,
            "due_date": "2024-10-31 00:00:00",
            "sub_total": "2775",
            "payment_info": null,
            "memo": null,
            "tax": "444",
            "discount": "0",
            "total": "3219",
            "created_at": "2024-11-13T12:31:59.000000Z",
            "updated_at": "2024-11-13T12:32:00.000000Z",
            "status": "draft",
            "path": "https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Invoice-Vaishali%20Jasoliya-DueDate31-Oct-2024.pdf",
            "user_id": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "file_name": "Harvey Mike-INVOICE.jpeg",
            "invoice_monite_id": null,
            "payment_status": null,
            "customer_id": "86",
            "paid_amount": null,
            "submitted_by": null,
            "submitted_at": null,
            "approved_by": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "approved_at": "2024-11-13 12:32:00",
            "deleted_by": null,
            "deleted_at": null,
            "cancelled_by": null,
            "cancelled_at": null,
            "paid_by": null,
            "paid_at": null,
            "discount_type": "amount",
            "discount_value": "0",
            "tax_percentage": "16",
            "monite_invoice_id": "fa00d1bc-890a-4edf-bb27-e9cb44b58619",
            "vat": "444"
        },
        {
            "id": 250,
            "company_id": 272,
            "receiver_id": null,
            "invoice_name": "Rwanda",
            "logo": "https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Harvey%20Mike-INVOICE.jpeg",
            "invoice_no": "INV1319802",
            "currency": "RWF",
            "issued_at": null,
            "due_date": "2024-10-31 00:00:00",
            "sub_total": "2775",
            "payment_info": null,
            "memo": null,
            "tax": "444",
            "discount": "0",
            "total": "3219",
            "created_at": "2024-11-13T12:31:59.000000Z",
            "updated_at": "2024-11-13T12:32:00.000000Z",
            "status": "draft",
            "path": "https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Invoice-Vaishali%20Jasoliya-DueDate31-Oct-2024.pdf",
            "user_id": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "file_name": "Harvey Mike-INVOICE.jpeg",
            "invoice_monite_id": null,
            "payment_status": null,
            "customer_id": "86",
            "paid_amount": null,
            "submitted_by": null,
            "submitted_at": null,
            "approved_by": "4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6",
            "approved_at": "2024-11-13 12:32:00",
            "deleted_by": null,
            "deleted_at": null,
            "cancelled_by": null,
            "cancelled_at": null,
            "paid_by": null,
            "paid_at": null,
            "discount_type": "amount",
            "discount_value": "0",
            "tax_percentage": "16",
            "monite_invoice_id": "fa00d1bc-890a-4edf-bb27-e9cb44b58619",
            "vat": "444"
        }
    ])
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const dispatch = useDispatch();

    console.log(data?.payment_link?.currency, "data?.currency");

    const [payload, setpayload] = useState({
        first_name: data?.customer?.contact_first_name,
        last_name: data?.customer?.contact_last_name,
        email: data?.customer?.contact_email,
        short_code: shortcode,
        currency: data?.payment_link?.currency,
        country_id: data?.payment_methods?.id
    })
    const [option, setOption] = useState<any>(

    )
    const [resdata, setResdata] = useState(data)
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    useEffect(() => {
        if (payReqrender && dataList?.INVOICE_PAYMENTLINK_CREATE_REQUEST == false) {
            setpayReqrender(false);
        }
    }, [dataList, payReqrender]);
    console.log(dataList.INVOICE_PAYMENTLINK_CREATE_SUCCESS, dataList?.INVOICE_PAYMENTLINK_CREATE_SUCCESS?.MerchantRequestID, "dataList.INVOICE_PAYMENTLINK_CREATE_SUCCESS");

    // useEffect(() => {
    //     if (payReqrender && dataList?.INVOICE_PAYMENTLINK_CREATE_REQUEST == true) {
    //         setSuccess_data("PANDING")
    //         setpayReqrender(false);
    //         setpayReqSuccrender(true);
    //         setpayReqResrender(true)
    //     }
    // }, [dataList, payReqrender]);
    useEffect(() => {
        if (payReqSuccrender && dataList?.INVOICE_PAYMENTLINK_CREATE_SUCCESS !== undefined) {
            setpayReqSuccrender(false);
            setpaymentStep(true);
            settrans(dataList.INVOICE_PAYMENTLINK_CREATE_SUCCESS);
            setcheckTransapi(true);
        }
    }, [dataList, payReqSuccrender]);
    useEffect(() => {
        if (trans && getTrans && !!trans.MerchantRequestID) {
            setGettrans(false);
            dispatch(paymentJourneyActions.TransStatusLink(dataList?.INVOICE_PAYMENTLINK_CREATE_SUCCESS?.MerchantRequestID) as any);
            let timer = setInterval(() => {
            }, 1000);
            if (!!timer) {
                clearInterval(timer);
            }
        }
    }, [trans, getTrans]);
    useEffect(() => {
        if (payReqResrender && dataList?.INVOICE_PAYMENTLINK_CREATE_FAILURE !== undefined) {

            console.log('useEffect4')
            setpayReqResrender(false);
            setSuccess_data("FAILURE")
        }
    }, [dataList, payReqResrender]);

    console.log(checkTransapi, dataList, dataList?.TRANSPAYLINK_SUCCESS, 'dataList');

    useEffect(() => {
        if (checkTransapi && dataList?.TRANSPAYLINK_SUCCESS !== undefined) {
            if (dataList?.TRANSPAYLINK_SUCCESS?.status == 'pending') {
                setSuccess_data("PANDING")
                setcheckTransapi(false);
                dispatch(paymentJourneyActions.TransStatusLink(dataList?.INVOICE_PAYMENTLINK_CREATE_SUCCESS?.MerchantRequestID) as any);
                setTimeout(() => {
                    setcheckTransapi(true);
                }, 1500)
            } else if (dataList?.TRANSPAYLINK_SUCCESS?.status == 'failed'
            ) {
                setSuccess_data("FAILURE")
                setcheckTransapi(false);
            } else if (dataList?.TRANSPAYLINK_SUCCESS?.status == 'success') {
                setcheckTransapi(false);
                setSuccess_data("SUCCESS")
                setOption(dataList?.TRANSPAYLINK_SUCCESS)
                setTimeout(() => {
                    setDataSuccess("SUCCESS")
                }, 4000)
            }
        }
    }, [dataList, checkTransapi]);

    console.log(dataList?.TRANSPAYLINK_SUCCESS, "dataList?.TRANSPAYLINK_SUCCESS");

    const Closepopup = () => {
        setSuccess_data("")
        setDataSuccess("")
    }
    const nextPage = () => {
        dispatch(paymentJourneyActions.CreateInvoiceLink(payload, setpayReqSuccrender, setpayReqResrender, setSuccess_data) as any);
        // (true);
    }
    console.log(data, "datadata655");
    // useEffect(() => {
    //     if (!!options && !!data?.payment_methods) {
    //         const findcurrencies: any = options.find((data: any) => data.label == data?.payment_methods?.name)


    //         var country_sortname_list = findcurrencies?.value?.toLowerCase()
    //         console.log(findcurrencies, country_sortname_list,data?.payment_methods?.name,options, "findcurrencies");
    //         console.log(country_sortname_list == "Ke", "condisan");

    //         setcountry_code(country_sortname_list)
    //     }
    // }, [data, options])
    return (
        <>
            <ThemeProvider theme={theme}>
                {success_data == "" ?
                    <Grid container>

                        <Grid item md={2}>

                        </Grid>
                        <Grid item md={8} xs={12} sm={12}>

                            <Box className={styles.contenar_box}>
                                <Grid container >
                                    <Grid item md={6} xs={12} sm={12} className={styles.Grid_prive}>
                                        <Box className={styles.box_conteanr_main}>
                                            <Box className={styles.img_main_dev_contean}>
                                                <Box sx={{ display: "flex", alignItems: "center" }} className={styles.img_box_img_contenar}>
                                                    {/* {!!resdata.logo ?
                                                        <img src={!!resdata && !!resdata.logo ? resdata.logo : ""} /> :
                                                        ''} */}
                                                    <Typography sx={{ paddingLeft: "0px" }} className={styles.des_heding_data}>
                                                        {data?.entity?.name}
                                                    </Typography>

                                                </Box>

                                            </Box>
                                            <Box sx={{ paddingTop: "10px" }} className={styles.box_contenar_amount}>
                                                {/* {data?.invoices[0]?.invoice_no} */}
                                                {data?.invoices.map((item:any, index:any) => {
                                                    return (
                                                        <Typography className={styles.des_heding_data}>
                                                            {item.invoice_no}
                                                        </Typography>
                                                    )
                                                })}

                                            </Box>
                                            <Box style={{ paddingTop: "0px" }} className={styles.box_contenar_amount}>
                                                {/* <Typography className={styles.des_heding_data}>
                                                    {!!resdata && resdata.item_name ? resdata.item_name : ""}
                                                </Typography> */}

                                                <Typography className={styles.Typography_amont}>
                                                    <span className={styles.spanclassName}>{!!data?.payment_link?.currency ? data?.payment_link?.currency : ""}</span>
                                                    {Number(parseFloat(data?.payment_link?.amount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                                </Typography>

                                            </Box>
                                            <Box className={styles.Box_classname}>
                                                <Box className={styles.BoxNamelist}>
                                                    <Typography className={styles.typodhitels}>Customer name</Typography>
                                                    <Typography className={styles.NameTYPO}>{data?.customer?.name}</Typography>
                                                </Box>
                                                <Box sx={{ paddingTop: "4px" }} className={styles.BoxNamelist}>
                                                    <Typography className={styles.typodhitels}>Email</Typography>
                                                    <Typography className={styles.NameTYPO}>{data?.customer?.contact_email}</Typography>
                                                </Box>
                                                <Box sx={{ paddingTop: "4px" }} className={styles.BoxNamelist}>
                                                    <Typography className={styles.typodhitels}>Phone number</Typography>
                                                    <Typography className={styles.NameTYPO}>{data?.customer?.contact_phone}</Typography>
                                                </Box>
                                                <Box sx={{ paddingTop: "4px" }} className={styles.BoxNamelist}>
                                                    <Typography className={styles.typodhitels}>Address</Typography>
                                                    <Typography className={styles.NameTYPO}>{data?.customer?.contact_line1}</Typography>
                                                </Box>
                                            </Box>
                                            <div className={styles.logo_bottom_this}>
                                                <Typography>Powered by</Typography>
                                                <div className={styles.div_img_logo}>
                                                    <a href='http://niobi.co/' target='_blank'>
                                                        <img src='/Preview_logo.svg' width={'100%'} height={'14px'} />
                                                    </a>
                                                </div>
                                                <Typography>/</Typography>  <a target='_blank' href='https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf'>Terms </a><Typography>and</Typography><a target='_blank' href='https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf'>Privacy.</a>
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        {/* {setp_data == 1 ?
                                            <Detailspayment
                                                nextpage={setSetp_data}
                                                payload={payload}
                                                setpayload={setpayload}
                                                data={resdata}
                                                setResdata={setAmount}
                                                setCurrency={setCurrency}
                                                resdata={amount}
                                                setcountry_code={setcountry_code}
                                                entityid={entityid}
                                                shortcode={shortcode}
                                            /> : */}

                                        <Setp2
                                            nextpage={nextPage}
                                            GoBack={setSetp_data}
                                            data={resdata}
                                            methoad={data}
                                            // entityid={entityid}
                                            shortcode={shortcode}
                                            payload={payload}
                                            setpayload={setpayload}
                                            contryCode={contryCode}
                                            contryCode2={contryCode2}
                                        />

                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={2}>

                        </Grid>
                    </Grid> : dataSuccess == "SUCCESS" ?
                        <TransactionDetails Close={Closepopup}
                            shortcode={shortcode}
                            data={option} type={option} />
                        :
                        <div className={styles.main_dev_classs}>
                            {success_data == 'PANDING' ?
                                <div className={styles.div_pading_data}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src="/logo 3.svg" />
                                        <p className={styles.des_data}>Kindly check your phone and <span className="helite_text">enter your Mpesa PIN</span>  to complete the payment.</p>
                                        <img width={45} height={45} src="/Niobi Loading Animation-Cross.gif" />
                                    </div>
                                </div>
                                : success_data == 'SUCCESS' ?
                                    <div className={styles.contenar_main_text_line}>
                                        <div>
                                            <Success_icon />
                                            <p className={styles.classname_text_key_minnn}>
                                                Payment Successful
                                            </p>
                                        </div>
                                    </div>
                                    : success_data == 'FAILURE' ?
                                        <div className={styles.FAILURE_contean}>
                                            <div className={styles.img_dataa}>
                                                <img src="/image 4.png" />
                                                <p className={styles.text_err_contenarr_minn}>
                                                    Payment Failed
                                                </p>
                                                <Button onClick={() => { window.location.reload(); }} className={styles.RetryButton}>Retry</Button>

                                            </div>

                                        </div>
                                        : ""
                            }
                        </div>}
            </ThemeProvider>
        </>
    )
}
export default Setp1;
const Success_icon = () =>
(
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
        <g clip-path="url(#clip0_7237_14869)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z" fill="#025041" />
        </g>
        <defs>
            <clipPath id="clip0_7237_14869">
                <rect width="56" height="56" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
)