import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import styles from './styles.module.scss';
import { history } from '../../_helpers';
import Login from '../auth/login/Login';
import { Register } from '../auth/register/Register';
import { ForgotPassword } from '../auth/login/ForgotPassword';
import NavPage from './Layout/NavPage';
import { Notifications } from './Layout/Notifications/Notifications';
import { Compliance } from './Layout/compliance/Compliance';
import { VerifyEmail } from '../auth/verify-email/VerifyEmail';
import { Updatepassword } from '../auth/updatepassword/updatepassword';
import { Changepassword } from '../auth/changepassword/changepassword';
import { Payables } from './Layout/Payables/Payables';
import { Counterparty } from './Layout/Receivables/Receivables';
import { Counterparts } from './Layout/Counterparts/Counterparts';
import { Balance } from './Layout/Balance/Balance';
import { Tags } from './Layout/Tags/Tags';
import { ApiKey } from './Layout/ApiKey/ApiKey';
import { CreateApiKey } from './Layout/ApiKey/CreateApiKey';
import { Gethelp } from './Layout/Gethelp/Gethelp';
import { dispatchStore } from '../../Store/store';
import { entityActions, userActions } from '../../_actions';
import { CompanyDetails } from './Layout/Settings/CompanyDetails';
// import { UserDetail } from './Layout/Settings/UserDetails';
import 'bootstrap/dist/css/bootstrap.css';
import { Bill } from './Layout/Bill/Bill';
import { BillsApproval } from './Layout/Bill/BillsApproval';
import { PaymentJourneyPage } from './Layout/PaymentJourney/PaymentJourney';
import { AccountManagement } from './Layout/Settings/components/AccountManagement';
import { Profile } from './Layout/Settings/components/Profile';
import { Verification } from './Layout/Settings/components/Profile/Profile';
import User_Management from './Layout/Settings/components/Profile/User_Management';
import Team_table from './Layout/Settings/components/Profile/Team_table';
import SelectCurrencyWorkSpace from './Layout/Settings/components/SelectCurrencyWorkspace/selectCurrencyWorkspace';
import Budget from '../app/Layout/budget/budget';
import IdelTimer from './IdelTimer';
import Bulktebal from './Layout/Bill/components/csvfile/Bulktebal';
import Create_Account from '../auth/Create_Account/Create_Account';
import New_create_account from '../auth/Create_Account/new_create_account';
import { Users } from '../../../src/features/app/Layout/Settings/components/Users';
import Accessibility from './accessibility';
import { Contact_Us } from '../app/Layout/Gethelp/Contact_Us';
import Redayrect_page from '../app/Layout/Gethelp/redayrect';
import Credentials from '../app/Layout/ApiKey/tebal_apikey';
import Create_New_Budget from '../app/Layout/budget/Create_New_Budget';
import { TourProvider } from '@reactour/tour';
import { getUser, isUserAuthenticated } from '../../_helpers';
import Edit_budget from '../app/Layout/budget/edit_Budget';
import Bulk_Payments from './Layout/Bulk_Payments/Bulk_Payments';
import BillsApproval_bulk from './Layout/Bulk_Payments/approve_payment';
import { CereteCounterparts } from './Layout/Counterparts/cereteCounterparts';
import Saler from './Layout/Sales/Sales';
import Create_your_invoice from '../auth/Create_your_invoice/Create_your_invoice';
import Integrations from './Layout/Sales/integrations_page';
import All_user from './Layout/Settings/components/Profile/all_user';
import Pay from './Layout/Pay/Pay';
import Invoice_Pay from './Layout/Invoice_link_pay/Pay';
import Payment_Link from './Layout/Payment_Link/Payment_Link';
import Detels_payment from './Layout/Payment_Link/detels_payment';
import CeretePaymentLink from './Layout/Payment_Link/CeretePaymentLink';
import VerifyIdentity from './Layout/Settings/components/Profile/components/AccountSetup/VerifyIdentity';
import SetupFailed from './Layout/Settings/components/Profile/components/AccountSetup/Setupfailed';
import DocumentUpload from './Layout/Settings/components/Profile/components/AccountSetup/DocumentUpload';
import AccountSetup from './Layout/Settings/components/Profile/components/AccountSetup/AccountSetup';
import { useNavigate } from 'react-router-dom';
import Login_change from '../auth/login/Login_change';
import NewRegister from '../auth/register/NewRegister';
import NewEntity from '../auth/entity/NewEntity';
import Entity from '../app/Layout/entity/NewEntity';
import Customers from './Layout/Customers/Customers';

import Products from './Layout/Products/products';
import CreateProducts from './Layout/Products/createProducts';

import NewCreate_Account_from from '../auth/Create_Account/new_create_account';
import { Create_Account_from } from '../auth/Create_Account/Create_Account_from';
import { DashboardV3 } from './Layout/Dashboard/DashboardV3';
import CreateCustomer from './Layout/Customers/CreateCustomer';
import Invoice from './Layout/invoice/invoice';
import Create_your_invoiceIn from './Layout/invoice/Create_your_invoice/Create_your_invoiceIn';
import PaymentInvoice from './Layout/PaymentInvoice/PaymentInvoice';
import Pay_ins from './Layout/PaymentInvoice/Pay_ins';
import initializeEcho from '../../_helpers/echo';
import Auth_Layout from '../auth/auth_layout/auth_layout';
import SelectCurrency from '../auth/selectCurrency';
import { selectAllData } from '../../_reducers/entity.reducer';
import { useSelector } from 'react-redux';
import AccountSetupCompleted from '../auth/Account-setup-completed';
import ReviewInformation from '../auth/reviewInformation';
import Categories from './Layout/Categories';
import ComposeEmail from './Layout/invoice/ComposeEmail';
import ConnectInvoice from './Layout/invoice/components/components/connectInvoice';
import InvoiceConnection from './Layout/invoice/components/components/connectInvoice';
import ConnectTransaction from './Layout/invoice/components/components/connectTransactions';
import InvoiceConnectionDetails from './Layout/invoice/components/components/connectInvoice/connectionDetails';
import TransactionConnectionDetails from './Layout/invoice/components/components/connectTransactions/connectionDetails';
function App() {
  // const userDetails = localStorage.getItem('user')
  const [isTimeout, setIsTimeout] = useState(false);
  const [render, setRender] = useState(true);
  const [setpNumberAuth, setSetpNumberAuth] = useState(1);
  const [setpNumberAuthtwo, setSetpNumberAuthtwo] = useState(1);
  const [renderGet, setRenderGet] = useState(true);
  const [stepObj, setStepObj] = useState<any>();
  const [environmentLoader, setEnvironmentLoader] = useState(false);

  const data = useSelector((state) => selectAllData(state));
  const user = getUser()?.data ?? '';

  const updateStep = (step: any, forward: any, refresh?: any) => {
    console.log(step, 'stepstepstep');
    var body = {
      step: step,
      forward: forward,
    };

    setEnvironmentLoader(true);
    dispatchStore(
      entityActions.setCurrentStepAction(body, forward, setEnvironmentLoader)
    );

    getStep();
  };

  const getStep = () => {
    // setEnvironmentLoader(true)
    dispatchStore(entityActions.getCurrentStepAction(setRenderGet));
    // setRenderGet(true);
  };

  useEffect(() => {
    if (!!user) {
      getStep();
    }
  }, [setpNumberAuth]);

  React.useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();
      console.log('beforeunload event triggered');
      const sesionTime: any = localStorage.getItem('sesionTime');
      const nowtime = Date.now();
      if (!!sesionTime && sesionTime != 0) {
        if (Number(sesionTime) < nowtime) {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          localStorage.removeItem('_expiredTime');
          window.location.href = '/login';
        }
      }
      const expiredTime: any = Number(Date.now() + 24 * 60 * 60 * 1000);
      localStorage.setItem('sesionTime', expiredTime);
    };

    // window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  React.useEffect(() => {
    const timer = new IdelTimer({
      timeout: 600,
      onTimeout: () => {
        localStorage.removeItem('_expiredTime');
        dispatchStore(
          userActions.logout(
            'auto',
            'Your session has expired. Kindly login again.'
          ) as any
        );
        setIsTimeout(true);
      },
      onExpired: () => {
        setIsTimeout(true);
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, []);
  const Array_Route = [
    { id: 1, name: 'step_1', pathname: '/v2/account-setup' },
    { id: 2, name: 'step_2', pathname: '/v2/document-upload' },
    { id: 3, name: 'step_3', pathname: '/v2/document-upload?step-3' },
    { id: 4, name: 'step_4', pathname: '/v2/entity' },
    { id: 5, name: 'step_5', pathname: '/v2/currency' },
    { id: 6, name: 'step_6', pathname: '/v2/reviews' },
    { id: 7, name: 'step_7', pathname: '/v2/verify-identity' },
    { id: 8, name: 'step_8', pathname: '/v2/account-setup-completed' },
  ];

  React.useEffect(() => {
    if (data?.GET_CURRENT_STEP_SUCCESS !== undefined && renderGet) {
      setRenderGet(false);
      const dataList = data?.GET_CURRENT_STEP_SUCCESS?.data;
      setStepObj(dataList);
      // setTimeout(() => {
      //   setEnvironmentLoader(false)
      // }, 2000);
    }
  }, [data?.GET_CURRENT_STEP_SUCCESS, renderGet]);
  // useEffect(() => {
  //   if (user?.data?.entity?.status !== "VERIFIED") {
  //     if (stepObj && stepObj.step_1 == true) {
  //       const steps = Object.keys(stepObj).filter(key => key.startsWith('step_'));
  //       const firstFalseStep = steps.find(step => !stepObj[step]);
  //       const firstFalseStepTrue = steps.find(step => stepObj[step]);
  //       console.log(firstFalseStepTrue, "firstFalseStepTrue");

  //       if (firstFalseStep) {
  //         const route = Array_Route.find(route => route.name === firstFalseStep);
  //         console.log(route, "routeroute");

  //         if (route) {
  //           if (window?.location?.pathname == route.pathname) {
  //             if (route.pathname.includes('step-3') || route.name == 'step_3') {
  //               window.location.href = route.pathname
  //             }
  //           } else {
  //             if (route.pathname.includes('step-3') || route.name == 'step_3') {

  //             } else {
  //               window.location.href = route.pathname;

  //             }
  //           }
  //         } else {
  //           console.log("All steps are true or no false steps found.");

  //         }
  //         // setSetpNumberAuth(route)
  //       }
  //     }
  //   }
  // }, [stepObj, window?.location?.pathname])

  console.log(user, 'userjlllll');
  // useEffect(() => {
  //   if (render&&(user?.entity?.status == "UNVERIFIED" || user?.entity?.status == "NEW")) {
  //     window.location.href = '/settings/account-setup';
  //     setRender(false)
  //   }
  // }, [render])
  const steps =
    !!user &&
    user.role_name &&
    (user.role_name == 'Accountant' ||
      user.role_name == 'Admin' ||
      user.role_name == 'Director')
      ? [
          {
            selector: '#fast_list_log',
            content:
              'Get a clear snapshot of your account details, inflows and outflows.',
          },
          {
            selector: '#Balance_list',
            content:
              'View your account details and activity and easily access detailed transaction information.',
          },
          {
            selector: '#invoice_product_tool',
            content: 'Effortlessly create professional, branded PDF invoices',
          },
          {
            selector: '#payment_link_log',
            content:
              'Generate payment links to share with customers for remote payments.',
          },
          {
            selector: '#thred_log',
            content:
              'Create and manage bills for a streamlined way to track your expenses.',
          },
          {
            selector: '#aporuwal_data',
            content: 'Manage bill and payment approvals efficiently.',
          },
          {
            selector: '#fast_secend_log',
            content:
              'Manage your payees, either individually or in bulk. Use our predefined payees for quick setup.',
          },
          {
            selector: '#categories_log',
            content:
              'Create and edit custom expense categories for your business ',
          },
          // {
          //   selector: '#Budgeting_list',
          //   content:
          //     'Effortlessly plan and monitor your outflows. Establish budgets, allocate categories, and compare actual spending against your objectives.',
          // },
          {
            selector: '#Workspace_list',
            content:
              ' Verify your business, view your account details, invite team members or integrate our payments API into your software platform within your workspace',
          },
          {
            selector: '#Get_Help_list',
            content:
              "Require assistance? Utilize our 'Get Help' feature for prompt customer support, FAQs, and enriching resources.",
          },
          {
            selector: '#Logout',
            content: 'Conclude your session using our Logout feature.',
          },
          {
            selector: '#Receive_payments',
            content: 'Add funds to your wallet by receiving payments here.',
          },
          {
            selector: '#Send_payment',
            content: 'Make fast and secure business payments.',
          },
          {
            selector: '#Total_Transactions_header',
            content: 'View the number and total amount of incoming payments.',
          },
          {
            selector: '#Total_Transactions_header2',
            content: 'Manage and track your paid and outstanding bills.',
          },
          // {
          //   selector: '#Total_Bills',
          //   content:
          //     'Effortlessly manage your outflows with a comprehensive view using the View All Bills feature. Stay organized while effortlessly tracking your bills.',
          // },
          {
            selector: '#Current_Month_Activity',
            content:
              'Access specific financial data by selecting desired dates for the current month.',
          },
          {
            selector: '#Recent_Transactions_header',
            content: 'View your most recent transactions at a glance.',
          },
          // {
          //   selector: '#Budget_header',
          //   content:
          //     'Compare your budgeted outflows with your actual spending at a glance',
          // },
          {
            selector: '#inflows_payment_method',
            content:
              'Track your incoming payments based on different payment methods',
          },
          {
            selector: '#payment_method',
            content:
              'Explore your spending patterns with a breakdown of outflows by payment methods',
          },
        ]
      : [
          {
            selector: '#fast_list_log',
            content:
              'Get a clear snapshot of your account details, inflows and outflows.',
          },
          {
            selector: '#Balance_list',
            content:
              'View your account details and activity and easily access detailed transaction information.',
          },
          {
            selector: '#thred_log',
            content:
              'Create and manage bills for a streamlined way to track your expenses.',
          },
          {
            selector: '#aporuwal_data',
            content: 'Manage bill and payment approvals efficiently.',
          },
          {
            selector: '#fast_secend_log',
            content:
              'Manage your payees, either individually or in bulk. Use our predefined payees for quick setup.',
          },
          {
            selector: '#categories_log',
            content:
              'Create and edit custom expense categories for your business ',
          },
          // {
          //   selector: '#Budgeting_list',
          //   content:
          //     'Effortlessly plan and monitor your outflows. Establish budgets, allocate categories, and compare actual spending against your objectives.',
          // },
          {
            selector: '#Workspace_list',
            content:
              ' Verify your business, view your account details, invite team members or integrate our payments API into your software platform within your workspace',
          },
          {
            selector: '#Get_Help_list',
            content:
              "Require assistance? Utilize our 'Get Help' feature for prompt customer support, FAQs, and enriching resources.",
          },
          {
            selector: '#Logout',
            content: 'Conclude your session using our Logout feature.',
          },
          {
            selector: '#Receive_payments',
            content: 'Add funds to your wallet by receiving payments here.',
          },
          {
            selector: '#Send_payment',
            content: 'Make fast and secure business payments.',
          },
          {
            selector: '#Receive_payments',
            content: 'Add funds to your wallet by receiving payments here.',
          },
          {
            selector: '#Send_payment',
            content: 'Make fast and secure business payments.',
          },
          {
            selector: '#Total_Transactions_header',
            content: 'View the number and total amount of incoming payments.',
          },
          {
            selector: '#Total_Transactions_header2',
            content: 'Manage and track your paid and outstanding bills.',
          },
          // {
          //   selector: '#Total_Bills',
          //   content:
          //     'Effortlessly manage your outflows with a comprehensive view using the View All Bills feature. Stay organized while effortlessly tracking your bills.',
          // },
          {
            selector: '#Recent_Transactions_header',
            content: 'View your most recent transactions at a glance.',
          },
          {
            selector: '#Current_Month_Activity',
            content:
              'Access specific financial data by selecting desired dates for the current month.',
          },

          {
            selector: '#inflows_payment_method',
            content:
              'Track your incoming payments based on different payment methods',
          },
          {
            selector: '#payment_method',
            content:
              'Explore your spending patterns with a breakdown of outflows by payment methods',
          },
        ];
  const steps_two = [
    {
      selector: '#fast_list_log',
      content:
        'Get a clear snapshot of your account details, inflows and outflows.',
    },
    {
      selector: '#invoice_product_tool',
      content: 'Effortlessly create professional, branded PDF invoices',
    },
    {
      selector: '#thred_log',
      content:
        'Create and manage bills for a streamlined way to track your expenses.',
    },
    {
      selector: '#fast_secend_log',
      content:
        'Manage your payees, either individually or in bulk. Use our predefined payees for quick setup.',
    },
    {
      selector: '#categories_log',
      content: 'Create and edit custom expense categories for your business ',
    },
    {
      selector: '#Workspace_list',
      content: ' View your account details within your workspace',
    },
    {
      selector: '#Logout',
      content: 'Conclude your session using our Logout feature.',
    },
    {
      selector: 'Total_Transactions_header',
      content: 'View the number and total amount of incoming payments.',
    },
    {
      selector: 'Total_Transactions_header2',
      content: 'Manage and track your paid and outstanding bills.',
    },
    {
      selector: '#Current_Month_Activity',
      content:
        'Access specific financial data by selecting desired dates for the current month.',
    },
    {
      selector: '#Recent_Transactions_header',
      content: 'View your most recent transactions at a glance.',
    },
    {
      selector: '#inflows_payment_method',
      content:
        'Track your incoming payments based on different payment methods',
    },
    {
      selector: '#payment_method',
      content:
        'Explore your spending patterns with a breakdown of outflows by payment methods',
    },
  ];
  const steps_three = [
    {
      selector: '#fast_list_log',
      content:
        'Get a clear snapshot of your account details, inflows and outflows.',
    },
    {
      selector: '#Balance_list',
      content:
        'View your account details and activity and easily access detailed transaction information.',
    },
    {
      selector: '#Workspace_list',
      content:
        ' View your account details, and integrate our payments API into your software platform within your workspace',
    },
    {
      selector: '#Get_Help_list',
      content:
        "Require assistance? Utilize our 'Get Help' feature for prompt customer support, FAQs, and enriching resources.",
    },
    {
      selector: '#Logout',
      content: 'Conclude your session using our Logout feature.',
    },
    // {
    //   selector: '#totalBalance_header',
    //   content: 'See your overall financial standing at a glance',
    // },
    {
      selector: 'Total_Transactions_header',
      content: 'View the number and total amount of incoming payments.',
    },
    {
      selector: 'Total_Transactions_header2',
      content: 'Manage and track your paid and outstanding bills.',
    },
    {
      selector: '#Current_Month_Activity',
      content:
        'Access specific financial data by selecting desired dates for the current month.',
    },
    {
      selector: '#Recent_Transactions_header',
      content: 'View your most recent transactions at a glance.',
    },
    {
      selector: '#inflows_payment_method',
      content:
        'Track your incoming payments based on different payment methods',
    },
    {
      selector: '#payment_method',
      content:
        'Explore your spending patterns with a breakdown of outflows by payment methods',
    },
  ];
  const [overlayVisible, setOverlayVisible] = useState(false);
  console.log(overlayVisible, 'overlayVisible');

  // React.useEffect(() => {
  //   const disableContextMenu = (e:any) => {
  //     e.preventDefault();
  //   };

  //   window.addEventListener('contextmenu', disableContextMenu);

  //   return () => {
  //     window.removeEventListener('contextmenu', disableContextMenu);
  //   };
  // }, []);
  // const =()=>{
  //   setSetpNumberAuth(1)
  //   if (typeof updateStep == 'function') {
  //     updateStep(1)
  //   }
  //   localStorage.setItem("registerStep", '1')
  // }
  return (
    <>
      {/* {overlayVisible ? <div className="screenshot-overlay"></div>
        : */}
      <div className={styles.app}>
        <Accessibility d={document} />

        <Router>
          <Routes>
            <Route
              path="v2/login"
              element={<Login_change path={'/settings/account-setup'} />}
            />
            <Route
              path="login"
              element={<Login_change path={'/v2/account-setup'} />}
            />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="update-password" element={<Updatepassword />} />
            <Route path="change-password" element={<Changepassword />} />
            <Route
              path="invitation-to-niobi"
              element={<New_create_account />}
            />
            <Route path="new-login" element={<Login />} />
            {/* <Route path="Auth_Layout" element={<Auth_Layout />}></Route> */}
            <Route
              path="Create_Account_from"
              element={<Create_Account_from />}
            />
            <Route
              path="Create_your_invoice"
              element={<Create_your_invoice data={'logout'} />}
            />
            <Route
              path="v2/reviews"
              element={
                <Auth_Layout
                  environmentLoader={environmentLoader}
                  updateStep={updateStep}
                  setSetpNumberAuthapp={setSetpNumberAuth}
                  setp={setpNumberAuth}
                >
                  <ReviewInformation
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                    path={'/v2/verify-identity'}
                  />
                </Auth_Layout>
              }
            />

            <Route
              path="entity"
              element={
                <Entity path={'/settings/account-setup'} api="/create-entity" />
              }
            />
            <Route
              path="v2/entity"
              element={
                <Auth_Layout
                  environmentLoader={environmentLoader}
                  setSetpNumberAuthapp={setSetpNumberAuth}
                  updateStep={updateStep}
                  setp={setpNumberAuth}
                >
                  <NewEntity
                    updateStep={updateStep}
                    setSetpNumberAuth={setSetpNumberAuth}
                    path={'/v2/currency'}
                    api="/v2/update-entity"
                  />
                </Auth_Layout>
              }
            />
            <Route
              path="v2/account-setup-completed"
              element={
                <Auth_Layout
                  environmentLoader={environmentLoader}
                  setSetpNumberAuthapp={setSetpNumberAuth}
                  updateStep={updateStep}
                  setp={setpNumberAuth}
                  sideBar={false}
                >
                  <AccountSetupCompleted />
                </Auth_Layout>
              }
            />
            <Route
              path="v2/account-setup"
              element={
                <Auth_Layout
                  environmentLoader={environmentLoader}
                  setSetpNumberAuthapp={setSetpNumberAuth}
                  updateStep={updateStep}
                  setp={setpNumberAuth}
                >
                  <AccountSetup
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                    path={'/v2/document-upload'}
                  />
                </Auth_Layout>
              }
            />
            <Route
              path="v2/currency"
              element={
                <Auth_Layout
                  environmentLoader={environmentLoader}
                  setSetpNumberAuthapp={setSetpNumberAuth}
                  updateStep={updateStep}
                  setp={setpNumberAuth}
                >
                  <SelectCurrency
                    updateStep={updateStep}
                    setSetpNumberAuth={setSetpNumberAuth}
                    path={'/v2/reviews'}
                  />
                </Auth_Layout>
              }
            />
            <Route
              path="v2/document-upload"
              element={
                <Auth_Layout
                  environmentLoader={environmentLoader}
                  setSetpNumberAuthapp={setSetpNumberAuth}
                  updateStep={updateStep}
                  setp={setpNumberAuth}
                >
                  <DocumentUpload
                    api="/v2/create-entity"
                    updateStep={updateStep}
                    path="/v2/entity"
                    // path="/v2/entity"
                    backpath="/v2/account-setup"
                    setSetpNumberAuth={setSetpNumberAuth}
                    onSave={() => {
                      console.log('On Save clicked');
                    }}
                  />
                </Auth_Layout>
              }
            />

            <Route
              path="v2/verify-identity"
              element={
                <Auth_Layout
                  environmentLoader={environmentLoader}
                  setSetpNumberAuthapp={setSetpNumberAuth}
                  updateStep={updateStep}
                  setp={setpNumberAuth}
                >
                  <VerifyIdentity
                    path="/v2/account-setup-completed"
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                  />
                </Auth_Layout>
              }
            />
            <Route path="pay/:entityid/:shortcode" element={<Pay />} />
            <Route path="invoice/payment-link/:shortcode" element={<Invoice_Pay />} />

            {/* <Route path="verify-identity" element={<VerifyIdentity />} /> */}

            {/* <Route
              path="/"
              element={
                <TourProvider
                  steps={
                    !!user && user.role_name && user.role_name == "Associate"
                      ? steps_two
                      : !!user &&
                        user.role_name &&
                        user.role_name == "Developer"
                      ? steps_three
                      : steps
                  }
                  position="right"
                  styles={{
                    popover: (base) => ({
                      ...base,
                      "--reactour-accent": "#025041",
                      borderRadius: 20,
                    }),
                  }}
                  // afterOpen={disableBody}
                  // beforeClose={enableBody}
                >
                  <NavPage />{" "}
                </TourProvider>
              }
            > */}

            <Route
              path="/"
              element={
                <TourProvider
                  steps={
                    !!user && user.role_name && user.role_name == 'Associate'
                      ? steps_two
                      : !!user &&
                        user.role_name &&
                        user.role_name == 'Developer'
                      ? steps_three
                      : steps
                  }
                  position="right"
                  styles={{
                    popover: (base) => ({
                      ...base,
                      '--reactour-accent': '#025041',
                      borderRadius: 20,
                    }),
                  }}
                  // afterOpen={disableBody}
                  // beforeClose={enableBody}
                >
                  <NavPage />{' '}
                </TourProvider>
              }
            >
              <Route
                path="setup-failed"
                element={
                  <Auth_Layout setp={setpNumberAuth}>
                    <SetupFailed />
                  </Auth_Layout>
                }
              />
              <Route path="payment-journey" element={<PaymentJourneyPage />} />
              <Route path="compliance" element={<Compliance />} />
              <Route path="Categories" element={<Categories />} />
              <Route path="payables" element={<Payables />} />
              <Route path='ComposeEmail' element={<ComposeEmail />} />
              <Route
                path="create-counterparts"
                element={<CereteCounterparts />}
              />
              <Route path="counterparts" element={<Counterparts />} />
              {/* <Route path="wallet" element={<Wallet />} /> */}
              <Route path="notifications" element={<Notifications />} />
              <Route
                path="dashboard"
                element={
                  <TourProvider
                    steps={
                      !!user && user.role_name && user.role_name == 'Associate'
                        ? steps_two
                        : !!user &&
                          user.role_name &&
                          user.role_name == 'Developer'
                        ? steps_three
                        : steps
                    }
                    position="right"
                    styles={{
                      popover: (base) => ({
                        ...base,
                        '--reactour-accent': '#025041',
                        borderRadius: 20,
                      }),
                    }}
                  >
                    <DashboardV3 />
                  </TourProvider>
                }
              />

              <Route path="budget" element={<Budget />} />
              <Route path="PaymentInvoice" element={<PaymentInvoice />} />
              <Route path="payin" element={<Pay_ins />} />
              <Route path="create_new_budget" element={<Create_New_Budget />} />
              <Route path="edit_budget" element={<Edit_budget />} />
              <Route path="bills" element={<Bill />} />
              <Route path="bills-approval" element={<BillsApproval />} />
              {/*<Route path="counterparts" element={<Counterparts />} />*/}
              <Route path="balance" element={<Balance />} />
              <Route path="Bulk_Payments" element={<Bulk_Payments />} />



              {/* invoice  */}
              <Route path='invoice' element={<Invoice />} />
              <Route
                path="create-invoice"
                element={<Create_your_invoiceIn data={'login'} />}
              />
              <Route path="customers" element={<Customers />} />

              <Route path="create-customer" element={<CreateCustomer />} />
              <Route
                path="create-invoice"
                element={<Create_your_invoiceIn data={'login'} />}
              />


              <Route path="customers" element={<Customers />} />
              <Route path="create-customer" element={<CreateCustomer />} />
              <Route path="products" element={<Products />} />
              <Route path="create-product" element={<CreateProducts />} />
              <Route path="invoice/connect-to-payment" element={<InvoiceConnection />} />
              <Route path="transaction/connect-to-invoice" element={<ConnectTransaction />} />
              <Route path="invoice/connection-detail" element={<InvoiceConnectionDetails />} />
              <Route path="transaction/transaction-detail" element={<TransactionConnectionDetails />} />


              
              <Route path="Sales" element={<Saler />} />
              <Route path="approvals">
                <Route path="bills-approval" element={<BillsApproval />} />
                <Route
                  path="approve_payment"
                  element={<BillsApproval_bulk />}
                />
              </Route>
              <Route path="gethelp">
                <Route path="Raise_a_Ticket" element={<Gethelp />} />
                <Route path="FAQs" element={<Contact_Us />} />
                <Route path="contact_us" element={<Redayrect_page />} />

                <Route
                  path="dashboard"
                  element={
                    <TourProvider
                      steps={
                        !!user &&
                        user.role_name &&
                        user.role_name == 'Associate'
                          ? steps_two
                          : !!user &&
                            user.role_name &&
                            user.role_name == 'Developer'
                          ? steps_three
                          : steps
                      }
                      position="right"
                      styles={{
                        popover: (base) => ({
                          ...base,
                          '--reactour-accent': '#025041',
                          borderRadius: 20,
                        }),
                      }}
                    >
                      <DashboardV3 />
                    </TourProvider>
                  }
                />
              </Route>
              <Route path="Bulktebal" element={<Bulktebal />} />
              <Route path="settings">
                <Route path="organisation" element={<CompanyDetails />} />
                <Route path="profile" element={<Profile />} />
                <Route path="UserDetail" element={<Users />} />
                <Route path="verification" element={<Verification />} />
                <Route
                  path="account-setup"
                  element={
                    <AccountSetup
                      updateStep={updateStep}
                      setSetpNumberAuth={setSetpNumberAuthtwo}
                      path={'/settings/document-upload'}
                    />
                  }
                />
                {/* <Route path="verify-identity" element={<VerifyIdentity />} /> */}
                <Route
                  path="document-upload"
                  element={
                    <DocumentUpload
                      updateStep={updateStep}
                      setSetpNumberAuth={setSetpNumberAuthtwo}
                      path="/settings/verify-identity"
                      backpath="/settings/account-setup"
                      onSave={() => {
                        console.log('On Save clicked');
                      }}
                    />
                  }
                />

                <Route
                  path="verify-identity"
                  element={<VerifyIdentity path="/settings/account-setup" />}
                />
                <Route path="setup-failed" element={<SetupFailed />} />

                <Route path="team" element={<Team_table />} />

                {/* select-currency workspace */}
                <Route
                  path="selectCurrency-workspace"
                  element={<SelectCurrencyWorkSpace />}
                />

                <Route path="team_user" element={<User_Management />} />
                <Route path="Payment_Link" element={<Payment_Link />} />
                <Route
                  path="Details_payment_Link"
                  element={<Detels_payment />}
                />

                <Route
                  path="CeretePaymentLink"
                  element={<CeretePaymentLink />}
                />
                <Route path="All-user" element={<All_user />} />
                <Route path="categories" element={<Tags />} />
                <Route path="apikey" element={<ApiKey />} />
                <Route path="Credentials" element={<Credentials />} />
                <Route path="createapikey" element={<CreateApiKey />} />
                {/*<Route path="company-details" element={<CompanyDetails />} />
              <Route path="user-details" element={<UserDetail />} /> */}
              </Route>
              <Route path="payables" element={<Payables />} />
            </Route>

            <Route
              path="v2/register"
              element={<NewRegister api="/register/app" path="/entity" />}
            />
            <Route
              path="register"
              element={
                <NewRegister api="/v2/register/app" path="/v2/account-setup" />
              }
            />
            <Route path="forgotpassword" element={<ForgotPassword />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
