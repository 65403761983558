import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import "./invoiceConnection.module.scss";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { authHeader, getUser } from '../../../../../../../../_helpers';
import { useSearchParams } from "react-router-dom";
import moment from "moment";


const TransactionConnectionDetails = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [transactionId, setTransactionId] = useState([])
    const [transactionDetails, setTransactionDetails] = React.useState<any>([]);
    const [Tagclass, setTagclass] = useState('');
    const [tagname, settagname] = useState<any>('');
    const [option, setOption] = useState<any>({
        id: ''
      });
console.log(transactionDetails,"transactionDetails");
const user = getUser()?.data ?? null;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // setAnchorEl(event.currentTarget);
        // dispatch(balanceActions.getPdf(props.data.reference) as any);
        // setDatadesplay(true)
        setTimeout(() => {
            // setBalanceRender(true)
        }, 3000)
    };

    const onClose = () => {
        navigate("/invoice");
    }

    useEffect(() => {
    const id: any = searchParams.get('id');
    setTransactionId(id);
}, [searchParams]);

  useEffect(() => {
        if (transactionId) {
            const fetchTransactionDetails = async () => {
                const requestOptions = {
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader(),
                    },
                };

                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/invoice/get-transaction/${transactionId}`,
                        requestOptions
                    );
console.log(response,"dsfsFsfsdffsfff");

                    if (response.data.success==true) {
                        setTransactionDetails(response.data.transaction);
                        setOption(response.data.transaction);
                        console.log('jjjjjj', response?.data?.transaction);
                        const data: any = response.data.transaction;
                        const newData: any = {}
                        // setOption(response.data.data.data);
                        let tagclass = '';
                        if (data.Type == 'pay_in') {
                          tagclass = 'tag_payin-tag'
                          settagname('Pay In')
                        } else if (data.Type == 'pay_out') {
                          tagclass = 'tag_payout-tag'
                          settagname('Pay Out')
                        } else if (data.Type == 'fees') {
                          tagclass = 'tag_fees-tag'
                          settagname('Fees')
                        }
                        setTagclass(tagclass);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    console.log("Finally, it's done!");
                }
            };

            fetchTransactionDetails();
        }
    }, [transactionId]);
    // useEffect(() => {
       
    //   }, [props.data]);
    console.log("transactionDetails =>", transactionDetails, transactionId);
    

  return (
  <div style={{ marginTop: "60px" }}>

            <div className="">
                <Modal.Body>
                <div className='trans-detail-container'>
                    <div className='trans-detail-container__heading' style={{ textAlign: "left" }}>
                        Transaction Details
                    </div>
                    <div className='trans-detail-containerX' >
            <div className='trans-detail-container__table' >
              {
                (!!tagname) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Type</  div>
                    <div className='right text-capitalize tag'>
                      <span className={`tag_color ${Tagclass}`}>
                        {tagname}
                      </span>
                    </div>
                  </div> : ""
              }
              {
                (!!option.reference) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Reference</div>
                    <div className='right'>{option.reference}</div>
                  </div> : ""
              }
              {
                (!!option.payment_channel) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Payment Channel</div>
                    <div className='right'>{option.payment_channel}</div>
                  </div> : ""
              }
              {
                (!!option.created_at) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Transaction Date</  div>
                    <div className='right'>{moment.tz(option.created_at, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                  </div> : ""
              }
              {
                (!!option.transaction_amount) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Transaction Amount</div>
                    <div className='right'>{option.currency} {!!option.transaction_amount ?
                      Number((parseFloat((option.transaction_amount))).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : 0}</div>
                  </div> : ""
              }
              {!!option.mobile && tagname == 'Pay In' && !!option.payee ?
                <div className='dflex b-bot'>
                  <div className='left'>Sender Name</div>
                  <div className='right'>{option.payee}</div>
                </div> : ""}
              {!!option.mobile && tagname == 'Pay In' ?

                <div className='dflex b-bot'>
                  <div className='left'>Sender Number</div>
                  <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                </div>
                : ""
              }
              {tagname == 'Pay Out' ?
                (!!option.payee) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Payee Name</div>
                    <div className='right'>{option.payee}</div>
                  </div> : "" : (!!option.beneficiary) ? <div className='dflex b-bot'>
                    <div className='left'>To</div>
                    <div className='right'>{tagname == 'Pay In' ? option.beneficiary : option.payee}</div>
                  </div> : ""
              }
              {option.payment_channel == "M-Pesa Paybill" && tagname == 'Pay Out' && !!option.beneficiary_public_name ?
                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Name</div>
                  <div className='right'>{option.beneficiary_public_name}</div>
                </div> : ""}
              {tagname == 'Fees' && !!option.beneficiary ?
                <div className='dflex b-bot'>
                  <div className='left'>Payee Name</div>
                  <div className='right'>{option.beneficiary}</div>
                </div> : ""}
              {option.payment_channel == "M-Pesa Send Money" && tagname == 'Pay Out' && !!option.beneficiary_public_name ?
                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Name</div>
                  <div className='right'>{option.beneficiary_public_name}</div>
                </div> : ""}
              {option.payment_channel == "M-Pesa Till" && tagname == 'Pay Out' && !!option.beneficiary ? <>

                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Name</div>
                  <div className='right'>Merchant Till</div>
                </div>
              </> : ""}
              {!!option.bank_name && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                <div className='dflex b-bot'>
                  <div className='left'>Bank Name</div>
                  <div className='right'>{option.bank_name}</div>
                </div> : ""}
              {!!option.account_name && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                <div className='dflex b-bot'>
                  <div className='left'>Payee Name</div>
                  <div className='right'>{option.account_name}</div>
                </div> : ""}
              {!!option.account_number && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Number</div>
                  <div className='right'>{option.account_number}</div>
                </div> : ""}

              {!!option.mobile && tagname == 'Pay Out' && option.payment_channel == "M-Pesa Send Money" ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Beneficiary Number</div>
                    <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                  </div></> : ""
              }
              {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Paybill' && !!option.paybill_number ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Beneficiary Paybill Number</div>
                    <div className='right'>{!!option.paybill_number ? option.paybill_number : ""}</div>
                  </div></> : ""}
              {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Paybill' && !!option.account_number ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Account Number</div>
                    <div className='right'>{!!option.account_number ?
                      option.account_number : ""}</div>
                  </div></> : ""}
              {option.payment_channel == "M-Pesa Paybill" ||
                option.payment_channel == "M-Pesa Till" ? (
                <div className="dflex b-bot">
                  <div className="left">Account Name</div>
                  <div className="right">{option?.verified_account_name ?? "N/A"}</div>
                </div>
              ) : (
                ""
              )}
              {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Till' && !!option.till_number ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Beneficiary Number</div>
                    <div className='right'>{!!option.till_number ? option.till_number : ""}</div>
                  </div></> : ""}
              {!!option.mobile && tagname == 'Pay Out' && !!user.entity.name ?
                <div className='dflex b-bot'>
                  <div className='left'>From</div>
                  <div className='right'>{user.entity.name}</div>
                </div> : ""}
              {user?.role_name == 'Developer' ?
                <>
                  {tagname == 'Pay Out' || tagname == 'Pay In' ?
                    <div className="dflex b-bot">
                      <div className="left">Third Party Reference 1</div>
                      <div className="right">{!!option.third_party_reference_1 ? option.third_party_reference_1 : "-"}</div>
                    </div> : ""}
                  {tagname == 'Pay Out' || tagname == 'Pay In' ?
                    <div className="dflex b-bot">
                      <div className="left">Third Party Reference 2</div>
                      <div className="right">{!!option.third_party_reference_2 ? option.third_party_reference_2 : "-"}</div>
                    </div> : ""}
                </> : ""}
              {
                (!!option.invoice_no) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Invoice No</div>
                    <div className='right' style={{ cursor: "pointer", textDecoration: "underline", fontWeight: "bold" }} id='adaDdADddd' onClick={() => {
                      if (!!option.payable_id) {
                        window.location.href = `bills?id=${option.payable_id}`
                      }
                    }}>{option.invoice_no}</div>
                  </div> : ""
              }
              {/* {
  !!option.team ? (
    (tagname === 'Pay Out') ? (
      <div className="dflex b-bot">
        <div className="left">Teams</div>
        <div
          className="right"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
          id="adaDdADddd"
        >
          {option.teams?.map((data: any, index: any) => (
            <Typography key={index} className="two_text_conteanana">
              {`${data.name}${index === option?.teams?.length - 1 ? "" : ", "}`}
            </Typography>
          ))}
        </div>
      </div>
    ) : null
  ) : (
    (tagname === 'Pay Out') && user?.role_name === 'Director' ? (
      <div className="dflex b-bot">
        <div className="left">Teams</div>
        <div>
          <div
            style={{
              margin: "10px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="right"
          >
          
            {option.teams?.length > 0 ? 
    option.teams.map((data: any, index: any) => (
        <Typography key={index} className="two_text_conteanana">
            {`${data.name}${index === option?.teams?.length - 1 ? "" : ", "}`}
        </Typography>
    )) 
    : "-"
} */}
              {/* {
                (tagname === 'Pay Out' || tagname === 'Fees') ? (
                  <div className="dflex b-bot" >
                    <div className="left">Teams</div>
                    <div
                      className="right"
                      style={{
                        display: "flex",
                        gap: "2px",
                        fontWeight: "bold",
                        flex: 1,
                        flexWrap: "wrap"
                      }}
                      id="adaDdADddd"

                    >
                      {transactionTeams?.length > 0 ? (
                        transactionTeams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            className="right"
                            style={{ marginTop: "10px" }}
                          >
                            {`${data.name}${index === transactionTeams.length - 1 ? " " : ",    "}`}
                          </Typography>
                        ))
                      ) : option?.payable?.teams?.length > 0 ? (
                        option.payable.teams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            className="right"
                            style={{ marginTop: "10px" }}
                          >
                            {`${data.name}${index === option.payable.teams.length - 1 ? " " : ",    "}`}
                          </Typography>
                        ))
                      ) : (
                        <Typography
                          className="right"
                          style={{ marginTop: "10px", marginLeft: "15%" }}
                        >
                          -
                        </Typography>
                      )}
                      {tagname === 'Pay Out' && (
                        <Button
                          style={{ fontSize: "12px", marginLeft: "20px" }}
                          className="complete-profile__ADDBTNHHA"
                          onClick={() => {
                            setAddTeamModal(true);
                          }}
                        >
                          + Add team
                        </Button>
                      )}
                    </div>
                  </div>
                ) : tagname === 'Pay In' ? (
                  <div className="dflex b-bot">
                    <div className="left">Teams</div>
                    <div
                      className="right"
                      style={{
                        marginLeft: "20px",
                        fontWeight: "bold",
                      }}
                      id="adaDdADddd"
                    >
                      {transactionTeams?.length > 0 ? (
                        transactionTeams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            style={{
                              display: "inline-flex",
                            }}
                            className="right"
                          >
                            {`${data.name}${index === transactionTeams.length - 1 ? " " : ", "}`}
                          </Typography>
                        ))
                      ) : option?.teams?.length > 0 ? (
                        option.teams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            style={{
                              display: "inline-flex",
                            }}
                            className="right"
                          >
                            {`${data.name}${index === option.teams.length - 1 ? " " : ", "}`}
                          </Typography>
                        ))
                      ) : (
                        "-"
                      )}
                      <Button
                        style={{ fontSize: "12px", marginLeft: "20px" }}
                        className="complete-profile__ADDBTNHHA"
                        onClick={() => {
                          setAddTeamModal(true);
                        }}
                      >
                        + Add team
                      </Button>
                    </div>
                  </div>
                ) : null
              } */}
              {option?.invoices?.length == 0 ? "" :
                <div className="dflex b-bot">
                  <div className="left">Invoice No</div>
                  <div className="right">
                    {option?.invoices?.map((item: any, index: any) => {
                      {
                        console.log(index, option?.invoices?.length, "itemitemitem");
                      }
                      return (
                        <>
                          {item?.invoice_no}{option?.invoices?.length == index + 1 ? "" : ','}{' '}
                        </>
                      )
                    })}
                  </div>
                </div>}


              {/* {addTeamModal ? (
                <ThemeProvider theme={theme}>
                  <Modal
                    show={addTeamModal}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    centered
                  ><Modal.Body>
                      <Box className="box_dialog_main">
                        <Typography className="Create_Team_heding">{"Select Team"}</Typography>
                        <div id="fast_name_inpurt_div" >
                          <Autocomplete
                            multiple
                            sx={{
                              width: "50%",
                              marginLeft: "100px",
                              marginTop: "10px",
                              "& .MuiInputBase-root": {
                                display: "flex",
                                alignItems: "center",
                                background: "#FFFFFF",
                                border: "0px solid #DDDDDD",
                                outline: "none",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#111111",
                                borderRadius: "8px",
                                padding: "7px 9px",
                              },
                            }}
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={teams}
                            value={selectedTeamList}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, newValue) => {
                              setSelectedTeamList(newValue);
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                          />
                        </div>

                        <Box className={"box_btn_cencal"} sx={{ justifyContent: "space-between", display: "flex" }}>
                          <Button
                            className={"cerete_user"}
                            onClick={() => { onClose_cerete() }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={{ backgroundColor: isHovered ? '#f0f0f0' : 'transparent', borderColor: isHovered ? '#025041' : '' }}>
                            Cancel
                          </Button>
                          <Button className={"savebtn"} style={{ marginLeft: "15px", height: "40px" }}
                            onClick={() => {
                              saveTeams(option.id)
                            }}>Save</Button>
                        </Box>
                      </Box></Modal.Body></Modal >
                </ThemeProvider>

              ) : (
                " "
              )} */}



            </div>
          </div>
                             <div className='trans-detail-container__btn-row' style={{ marginTop: "40px" }}>
                         <Button className='trans-detail-container__btn-row__green'
                            style={{
                                backgroundColor: "white",
                                border: "1px solid black"
                            }}
                            onClick={() => {
                                onClose()
                                }}>
                           Close
                        </Button>
                        <Button className='trans-detail-container__btn-row__green'
                            onClick={handleClick}
                        >Share</Button>
                    </div>
                          </div>
                </Modal.Body>
            </div>
  </div>
  )
};

export default TransactionConnectionDetails;