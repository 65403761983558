
import styles from './Pay.module.scss';
import Setp1 from './components/setp1';
import { useParams, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import Modal from 'react-bootstrap/Modal'
const Payment_Link = () => {
  const MockData = {
    amount: 10,
    business_name: "ABC",
    callback_url: "https://dev.wallet.niobi.co/api/pay"
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const { entityid, shortcode } = useParams();
  const [linkRender, setlinkRender] = useState(true);
  const [linkData, setlinkData] = useState();
  const [environment, setEnvironment] = useState("")

  const dataList = useSelector((state) => selectPaymentJourneyData(state))
  const dispatch = useDispatch();
  console.log(entityid, shortcode, 'shotcode');
  const [showModal, setShow] = React.useState(false);
  const onClose = () => {
    setShow(false);

  };
  useEffect(() => {
    const popup = searchParams.get('environment');
    if (!!popup) {
      setEnvironment(popup)
    } else {
    }
  }, [searchParams]);
  useEffect(() => {
    if (!!shortcode && shortcode != undefined) {
      console.log(shortcode)
      dispatch(paymentJourneyActions.getPaymentLinkByShortcode(shortcode) as any)
      setTimeout(() => {
        setlinkRender(true);
      }, 1500)
    }
  }, [shortcode]);

  useEffect(() => {
    if (linkRender && dataList?.PAYMENTLINKSHORTCODE_SUCCESS !== undefined) {
      setlinkRender(false);
      setlinkData(dataList.PAYMENTLINKSHORTCODE_SUCCESS);
      if (dataList?.PAYMENTLINKSHORTCODE_SUCCESS?.status == "inactive") {
        setShow(true)
      }
    }
  }, [dataList, linkRender]);
  console.log(linkData, 'linkData shortcode')

  return (
    <>
      {
        linkData && entityid && shortcode&&showModal==false ?
          <Setp1
            data={linkData}
            entityid={entityid}
            shortcode={shortcode}
            environment={environment}
          />
          : ""
      }

      <Modal
        show={showModal}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby='ModalHeader'
        className='topup-modal trans-modal'
        id='afaFafffffFFff'
        centered
      >
        <div className={styles.divapopupdata}>
          <p className={styles.divaptages}>
            We apologize, but the payment link is currently inactive. Please retry with an active Payment link or reach out to <a>support@niobi.co</a> for assistance.
          </p>
        </div>
      </Modal>
    </>
  )
}
export default Payment_Link;

