import { Box, Typography } from "@mui/material";
import styles from '../Pay.module.scss';
import { Formik, Field, ErrorMessage } from 'formik'
import { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Button, InputGroup, Form } from "react-bootstrap";
import { getBase64, getKESFormate } from "../../../../../_helpers/common";
import PhoneInput from 'react-phone-input-2';
import { getProperties, getUser, isUserAuthenticated } from "../../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../_reducers/paymentJourney.reducer";
import countryList from 'react-select-country-list';

interface EntityRequest {
    First_name: string;
    Last_name: string;
    Email: string;
    country_id: string;
    amount: string;
    Mobile: number;
    currency: string;
}
interface countryProps {
    dialCode: string
}
interface Props {
    nextpage: any;
    payload: any;
    setpayload: any;
    data: any;
    entityid: any;
    shortcode: any;
    setcountry_code: any;
    setResdata: any;
    resdata: any;
    setCurrency: any;
    entity:any;
    setEntitydata:any;
}

interface payloadProps {
    first_name: any;
    last_name: any;
    short_code: any;
    entity_id: any;
    mobile?: any;
    amount?: any;
    email?: any;
    country_id?: any;
}
const Detailspayment = ({ payload, setpayload, nextpage, data, entityid, shortcode, setcountry_code, setResdata, setCurrency, resdata,entity,setEntitydata }: Props) => {
    const [enableButton, setEnableButton] = useState(false)
    const [startgetapi, setStartgetapi] = useState(true)
    const [startgetapicountry, setStartgetapicountry] = useState(true)
    const [render, setRender] = useState(true);
    const [CurrenciesList, setCurrenciesList] = useState([])
    const [CountryList, setCountryList] = useState([])
    const [listStore, setListStore] = useState(true)
    const [countryNameget, setCountryNameget] = useState('')
    const dispatch = useDispatch()
    const dataList = useSelector((state) => selectPaymentJourneyData(state))

    const numberMask = createNumberMask({
        prefix: '',
        suffix: '' // This will put the dollar sign at the end, with a space.
    })
    console.log(payload, dataList, data, CountryList, "payloadpayload");
    console.log(entity,"entityentity");

    
    const [submitData, setsubmitData] = useState(false)
    const options = useMemo(() => countryList().getData(), [])
    useEffect(() => {
        if (listStore && !!CountryList) {
            const findcurrencies: any = CountryList.find((item: any) => item.id == data.country_id)
            setCountryNameget(findcurrencies?.name)
        }
    }, [listStore,CountryList])
    const validationSchema = Yup.object().shape({
        First_name: Yup.string()
            .required('Please enter your first name.'),
        Email: Yup.string()
            .required('Please enter a valid email address.').email("Please enter a valid email address."),
        country_id: Yup.string()
            .required('Please select the country where the business is located.'),
        currency: Yup.string()
            .required('Please enter the Currency.'),
    })

    useEffect(() => {
        if (data.country_id > 0) {
            if (startgetapicountry && dataList?.GETCOUNTRIES_SUCCESS !== undefined) {
                setCountryList(dataList?.GETCOUNTRIES_SUCCESS)
                setStartgetapicountry(false)
            }
        } else {
            if (startgetapicountry && dataList?.GETCOUNTRIES_BY_CURRENCY_SUCCESS !== undefined) {
                setCountryList(dataList?.GETCOUNTRIES_BY_CURRENCY_SUCCESS)

                setStartgetapicountry(false)
            }
        }
    }, [startgetapicountry, dataList])
    
    useEffect(() => {
        if (!!options && !!countryNameget) {
            const findcurrencies: any = options.find((data: any) => data.label == countryNameget)


            var country_sortname_list = findcurrencies?.value?.toLowerCase()
            console.log(findcurrencies, country_sortname_list, "findcurrencies");
            console.log(country_sortname_list == "Ke", "condisan");

            setcountry_code(country_sortname_list)
        }
    }, [countryNameget, options])
    useEffect(() => {
        if (render) {
            setRender(false);
            dispatch(paymentJourneyActions.getCurrencies() as any)
            dispatch(paymentJourneyActions.getCountries() as any)
        }
    }, [render])
    useEffect(() => {
        if (startgetapi && dataList?.GETCURRENCIES_SUCCESS !== undefined) {
            setCurrenciesList(dataList?.GETCURRENCIES_SUCCESS)
            setStartgetapi(false)
        }
    }, [startgetapi, dataList])
    const Countries = (data: any) => {
        dispatch(paymentJourneyActions.getCountriesByCurrency(data) as any)
        setTimeout(() => {
            setStartgetapicountry(true)
        }, 4000)

    }
    const handleSubmit = (e: any) => {
        if (!data.amount) {
            if (e.amount <= 0) {
                setsubmitData(false)
            } else {
                nextpage('2')
                let paymentData: payloadProps = {
                    first_name: e.First_name,
                    last_name: e.Last_name,
                    email: e.Email,
                    short_code: shortcode,
                    entity_id: entityid,
                    country_id: e.country_id
                }
                if (!data.amount) {
                    paymentData.amount = parseFloat(e.amount);
                }
                setpayload(paymentData)
            }
        }
        else {
            nextpage('2')
            let paymentData: payloadProps = {
                first_name: e.First_name,
                last_name: e.Last_name,
                email: e.Email,
                short_code: shortcode,
                entity_id: entityid,
                country_id: e.country_id
            }
            if (!data.amount) {
                paymentData.amount = parseFloat(e.amount);
            }
            setpayload(paymentData)
        }
    }
    return (
        <>
            <Box className={styles.from_contean_data}>
                <Typography className={styles.heding_from}>Please enter your details</Typography>
                <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                        <>
                            <div className='company-details-container__wrapper'>
                                <Form className='form-input' onSubmit={handleSubmit}>

                                    <div className="input-form-wrapper dot">

                                        <label>First Name</label>
                                        <input name="First_name"
                                            type="text"
                                            placeholder="First name"
                                            value={entity?.First_name}
                                            onChange={(e: any) => {
                                                handleChange(e)
                                                setEnableButton(true)
                                                setEntitydata({
                                                    First_name: e.target.value,
                                                    Last_name: entity?.Last_name,
                                                    Email: entity?.Email,
                                                    country_id: entity.country_id,
                                                    amount: entity.amount,
                                                    Mobile: 0,
                                                    currency: entity.currency,
                                                  })
                                            }}
                                            style={{ background: "#fff" }}
                                            className={'text-field' + (errors.First_name && touched.First_name ? ' is-invalid' : '')} />

                                        <ErrorMessage name="First_name" component="div" className="invalid-feedback red" />
                                    </div>
                                    <div className="input-form-wrapper">

                                        <label>Last Name</label>
                                        <input name="Last_name"
                                            style={{ background: "#fff" }}
                                            type="text"
                                            value={entity.Last_name}
                                            placeholder="Last name"
                                            onChange={(e: any) => {
                                                handleChange(e)
                                                setEnableButton(true)
                                                setEntitydata({
                                                    First_name: entity.First_name,
                                                    Last_name: e.target.value,
                                                    Email: entity?.Email,
                                                    country_id: entity.country_id,
                                                    amount: entity.amount,
                                                    Mobile: 0,
                                                    currency: entity.currency,
                                                  })
                                            }}
                                            className={'text-field'} />
                                    </div>
                                    <div className="input-form-wrapper dot">

                                        <label>Email</label>
                                        <input name="Email"
                                            type="text"
                                            placeholder="Email"
                                            value={entity.Email}
                                            style={{ background: "#fff" }}
                                            onChange={(e: any) => {
                                                handleChange(e)
                                                setEnableButton(true)
                                                setEntitydata({
                                                    First_name: entity.First_name,
                                                    Last_name: entity.Last_name,
                                                    Email: e.target.value,
                                                    country_id: entity.country_id,
                                                    amount: entity.amount,
                                                    Mobile: 0,
                                                    currency: entity.currency,
                                                  })
                                            }}
                                            className={'text-field' + (errors.Email && touched.Email ? ' is-invalid' : '')} />
                                        <ErrorMessage name="Email" component="div" className="invalid-feedback red" />

                                    </div>
                                    <div style={{width:"100%"}}>
                                            <div
                                                className="input-form-wrapper dot"
                                                style={{ width: "100%", padding: 0 }}
                                            >
                                                <label>Currency</label>
                                                <Form.Select
                                                    disabled={!!data.currency ? true : false}
                                                    name='currency'
                                                    onChange={(e: any) => {
                                                        handleChange(e)
                                                        Countries(e.target.value)
                                                        setCurrency(e.target.value)
                                                    }}
                                                    value={values.currency}
                                                    className='select_box_kes'
                                                >
                                                    <option selected value="">Currency</option>
                                                    {CurrenciesList?.map((arrayElement: any, index: number) => {
                                                        return (
                                                            <option value={arrayElement?.currency} key={index + 1}>{arrayElement.currency}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                <ErrorMessage name="currency" component="div" className="invalid-feedback red" />
                                            </div>
                                        </div>
                                   
                                    <div className="input-form-wrapper dot">

                                        <label>Country</label>
                                        <Form.Select
                                            disabled={data.country_id > 0 ? true : !values.currency ? true : false}
                                            name="country_id"
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                const findcurrencies: any = CountryList.find((data: any) => data.id == e.target.value)
                                                setCountryNameget(findcurrencies?.name)
                                            }
                                            }
                                            className={
                                                'text-field'
                                            }
                                            value={values.country_id}
                                        >
                                            <option selected value="">Select country</option>
                                            {CountryList?.map((arrayElement: any, index: number) => {
                                                return (
                                                    <option value={arrayElement?.id}

                                                        key={index + 1}>
                                                        {arrayElement.name}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                        <ErrorMessage name="country_id" component="div" className="invalid-feedback red" />
                                    </div>
                                    <div className="className_divmenu">
                                        <div style={{width:"100%"}}>
                                            <div className="input-form-wrapper dot ">
                                                <label>Amount</label>
                                                <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>
                                                    <MaskedInput
                                                        mask={numberMask}
                                                        disabled={!!data?.amount ? true : false}
                                                        style={{ height: '100%' }}
                                                        value={values.amount}
                                                        className="doc-form-input form-control"
                                                        placeholder="Total"
                                                        name='amount'
                                                        onChange={(e) => {
                                                            const kesValue = getKESFormate(e.target.value);
                                                            const obj = e;
                                                            setResdata(e.target.value)
                                                            console.log(kesValue, 'kesValue');
                                                            handleChange(e)
                                                        }}
                                                        aria-describedby="basic-addon3"
                                                    />
                                               
                                                </InputGroup>
                                                {!values.amount && submitData == true ?
                                                    <div className={styles.ErrorMessage}>Enter a Amount in the required field</div>
                                                    : ""}

                                            </div>
                                        </div>
                                        
                                    </div>
                                    <Button style={{ marginTop: "20px" }} className='payDetails-modal__btn-row__green'
                                        variant="primary" type="submit"
                                        onClick={() => { setsubmitData(true) }}
                                    >
                                        Next
                                    </Button>
                                </Form>
                            </div>
                        </>
                    )}
                </Formik>
            </Box>
        </>
    )
}
export default Detailspayment;