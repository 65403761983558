import { Box, Grid } from "@mui/material";
import styles from '../Pay.module.scss';
import { Button } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../_reducers/paymentJourney.reducer";
import { useEffect, useState } from 'react';

interface Props {
    nextpage: any;
    data: any;
    entityid: any;
    shortcode: any;
    payload: any;
    setpayload: any;
    contryCode: any;
    GoBack: any;
    environment: any;
}
const Setp2 = ({ payload, setpayload, data, entityid, shortcode, nextpage, GoBack, contryCode, environment }: Props) => {
    const [tabdata, setTabdata] = useState()
    const [correspondent_data, setCorrespondent_data] = useState("")
    const [pohon_data, setPhone] = useState<any>()
    const [data_stastes, setStast_data] = useState(false)
    const [startgetapi, setStartgetapi] = useState(true)
    const [render, setRender] = useState(true);
    const [countryList_api, setCountrtList_api] = useState<any>([])
    const [country_sortname, setCountry_sortname] = useState<any>('')
    const dispatch = useDispatch()
    const dataList = useSelector((state) => selectPaymentJourneyData(state))

    console.log(payload, "payloadpayload");

    useEffect(() => {
        if (render) {
            setRender(false);
            dispatch(paymentJourneyActions.getCountriesDetails(payload.country_id) as any)
        }
    }, [render])

    useEffect(() => {
        if (startgetapi && dataList?.GETCOUNTRIES_DETAILS_SUCCESS !== undefined) {
            setCountrtList_api(dataList?.GETCOUNTRIES_DETAILS_SUCCESS)
            console.log(dataList?.GETCOUNTRIES_DETAILS_SUCCESS?.methods[0]);

            setTabdata(dataList?.GETCOUNTRIES_DETAILS_SUCCESS?.methods[0]?.id)
            setCorrespondent_data(dataList?.GETCOUNTRIES_DETAILS_SUCCESS?.methods[0]?.correspondent)
            setStartgetapi(false)
        }
    }, [startgetapi, dataList])

    const handsubmit = () => {
        if (environment == "pro") {
            if (pohon_data.length == 12 && !!tabdata) {
                let payloadData = payload;
                payloadData.mobile = pohon_data;
                payloadData.country_payment_method_id = tabdata
                payloadData.correspondent = correspondent_data
                setpayload(payloadData);
                nextpage()
            }
        } else {
            if (pohon_data.length > 4 && !!tabdata) {
                let payloadData = payload;
                payloadData.mobile = pohon_data;
                payloadData.country_payment_method_id = tabdata
                payloadData.correspondent = correspondent_data
                setpayload(payloadData);
                nextpage()
            }
        }
    }
    return (
        <>
            <Box className={styles.from_contean_data_setp2} sx={{ position: "relative" }}>
                <Box className={styles.tab_contean_button}>
                    <Grid container>
                        {countryList_api?.methods?.map((arrayElement: any, index: number) => {
                            return (
                                <Grid item md={6} sm={6} xs={6}>
                                    <Button className={tabdata == arrayElement.id ? styles.mpesa_button : styles.difolt_button}
                                        onClick={() => {

                                            setTabdata(arrayElement.id)
                                            setCorrespondent_data(arrayElement.correspondent)
                                        }}>
                                        <div className={styles.mpsaimg}>
                                            <img width={'100%'} height={'100%'} src={arrayElement.logo} />
                                        </div>
                                        {arrayElement.title}</Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                <div style={{ paddingTop: "20px" }}>

                    <p style={{ fontWeight: "400" }} className="discrip_the_ahhahas">
                        Hello, {payload?.first_name}{' '}{payload?.last_name}
                    </p>
                    <p style={{ fontWeight: "400" }} className="discrip_the_ahhahas">

                        You will shortly receive a prompt on your phone to enter your mobile money PIN to complete the payment. Please ensure your phone is on and unlocked to complete the process.
                    </p>

                    <div className='company-details-container__wrapper'>
                        <div className='input-form-wrapper'>
                            <label>Mobile</label>
                            <PhoneInput
                                country={contryCode}
                                // prefix='+'
                                value={pohon_data}
                                onlyCountries={[contryCode]}
                                localization={{ de: contryCode }}

                                onChange={(value, country, e, formattedValue) => {
                                    console.log(value, e, 'aahauyaahaha');
                                    setPhone(value)
                                }}
                                inputClass={"inputBx-mobile readonly"}
                            />
                            {environment == "pro" ?
                                <>
                                    {data_stastes == true && pohon_data == undefined ? (
                                        <p className="err_calss_display">Mobile is required</p>
                                    ) : !!pohon_data && pohon_data.length != 12 ? (
                                        <p className="err_calss_display">
                                            Please enter 9 digit phone number
                                        </p>
                                    ) : (
                                        ""
                                    )}</>
                                :
                                <>
                                    {data_stastes == true && pohon_data == undefined ? (
                                        <p className="err_calss_display">Mobile is required</p>
                                    ) : ("")
                                    }
                                </>
                            }
                            <div className={styles.button_bottomcontean}>
                                <div className={styles.buttonContean}>
                                    <Button id={styles.contenButtonPay} className="balance__white-btn"
                                        onClick={() => {
                                            GoBack(1)
                                        }}
                                    >
                                        Go Back
                                    </Button>
                                    {!!pohon_data ?
                                        <Button
                                            id={styles.contenButtonPay}
                                            className="balance__save-btn"
                                            onClick={() => {
                                                handsubmit()
                                                setStast_data(true)
                                            }}
                                        >Pay</Button> : <Button
                                            id={styles.contenButtonPay}
                                            className="balance__save-btn"
                                            onClick={() => {
                                                setStast_data(true)
                                            }}
                                        >Pay</Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default Setp2;