import { Box, Grid, Typography } from '@mui/material';
import styles from './connectTransactionTable.module.scss';
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { isUserAuthenticated, authHeader } from '../../../../../../../../_helpers';
import axios from 'axios';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from '../../../../../../../../_actions/counterparts.actions'
import { TebalNoData } from '../../../../../Skeleton/TebalNoData';
import { ListSkeleton } from '../../../../../Skeleton/Skeleton';
import { useNavigate } from "react-router-dom";
import { selectAllData } from "../../../../../../../../_reducers/dashboard.reducer";

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}
interface Props {
    transactionData: any;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'Customer Name',
        numeric: true,
        disablePadding: false,
        label: 'Customer Name',
    },
    {
        id: 'Invoice Number',
        numeric: true,
        disablePadding: false,
        label: 'Invoice Number',
    },
    {
        id: 'Invoice Date',
        numeric: false,
        disablePadding: true,
        label: 'Invoice Date',
    },
    {
        id: 'Invoice Amount',
        numeric: true,
        disablePadding: false,
        label: 'Invoice Amount',
    },
];

const TransactinTable = (props: Props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [loderinapi, setLoderapi] = React.useState(false);
    const [datatabal, setDatatebal] = React.useState<any>([]);
    const [selectData, setSelecetData] = useState([])
    const [totalCount, setTotalCount] = React.useState(10);
    const [pageneshn, setPagelist] = React.useState(1);
    const [remove, setRemovedata] = React.useState(10);
    const [dense, setDense] = React.useState(false);
    const [selected, setSelected] = React.useState<any>([]);
    const [Redusar, setRedusar] = useState(true)
    const [loader, setLoader] = useState(false)
    const [listRender, setListRender] = useState(false)
    const [selectedMoniteIds, setSelectedMoniteIds] = React.useState<any>([]);
    const data = useSelector((state) => selectAllData(state));
    const [isInitialized, setIsInitialized] = useState(false);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [totalSelectedAmount, setTotalSelectedAmount] = useState<number>(0);



    const getBalanceList = async (
        pageneshn: any
    ) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", ...authHeader() },
        };

        const pagelimit = 10;
        setLoderapi(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/invoice/get-invoices/${props.transactionData.transactionCurrency}?page=${pageneshn}&limit=${pagelimit}`,
                requestOptions
            );

            if (response.data.success) {
                setDatatebal(response.data.data.data);
                setTotalCount(response.data.data.total);
                const newSelected: any = [];

                for (const element of response.data.data.data) {
                    if (element.status !== "paid") {
                        newSelected.push(element.id);
                    }
                }

                setSelecetData(newSelected);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setTimeout(() => setLoderapi(false), 2000);
        }
    };

    // handle select All
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected: any = []
            for (let index = 0; index < datatabal.length; index++) {
                const element = datatabal[index];
                if (element.status == "paid") {

                } else {
                    newSelected.push(element.id)
                }
            }
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };


    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });

    useEffect(() => {
        if (props.transactionData?.transactionCurrency) {
            setIsInitialized(true);
        }
    }, [props.transactionData?.transactionCurrency]);

    useEffect(() => {
        if (isInitialized && isUserAuthenticated() && Redusar) {
            getBalanceList(pageneshn);
            dispatch(counterpartsActions.getCustomerDropDoun() as any);
            setTimeout(() => setListRender(true), 4000);
        }
    }, [isInitialized, Redusar]);


    // handle select 
    const handleClick = (transactionId: string, moniteId: number, selectedAmount: number) => {
        const selectedIndex = selected.indexOf(transactionId);
        let newSelected: readonly string[] = [];
        let newMoniteIds: number[] = [...selectedMoniteIds];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, transactionId);
            newMoniteIds = newMoniteIds.concat(moniteId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newMoniteIds = newMoniteIds.filter((id) => id !== moniteId);
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newMoniteIds = newMoniteIds.filter((id) => id !== moniteId);
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
            newMoniteIds = newMoniteIds.filter((id) => id !== moniteId);
        }

        setSelected(newSelected);
        setSelectedMoniteIds(newMoniteIds);

        const amountToAddOrRemove = parseFloat(selectedAmount.toString()) || 0;
        let updatedAmount = parseFloat(totalSelectedAmount.toString()) || 0;

        if (selectedIndex === -1) {
            updatedAmount = parseFloat((updatedAmount + amountToAddOrRemove).toFixed(2));
        } else {
            updatedAmount = parseFloat((updatedAmount - amountToAddOrRemove).toFixed(2));
        }

        setTotalSelectedAmount(updatedAmount);
        setSelectedCount(newSelected.length);
    };


    // handle link transaction
    const handleLinkTransaction = (status: any) => {
        if (status === "submit") {
            setLoader(true)
            const body = {
                "transaction_id": props.transactionData.transactionId,
                "monite_invoice_ids": selectedMoniteIds
            }

            dispatch(counterpartsActions.LinkTransactionToInvoice(body, props.transactionData.transactionId) as any);
            setTimeout(() => {
                setLoader(false)
            }, 2000);

            // setTimeout(() => {
            //     navigate(
            //          "/transaction/transaction-detail?id=" + props.transactionData.transactionId, 
            //          { replace: true, state: { id: props.transactionData.transactionId, } });
            // }, 2000);
        } else if (status === "cancel") {
            navigate("/invoice")
        }
    }

    // console.log("data =>", selectedCount, totalSelectedAmount);


    return (
        <>
            <ThemeProvider theme={theme}>
                <ListSkeleton loader={loderinapi} height={'100vh'} width={'100%'}
                    element={
                        <>
                            <Grid container>
                                <Grid item xs={4}>
                                    <div className={styles.invoiceSummary}>
                                        <label className={styles.headingSummaryTitle}>Total summary of invoice</label>
                                        <div className={styles.summaryRow}>
                                            <div className={styles.summaryLabel}>Selected payin amount</div>
                                            <div className={styles.summaryValue}>{props.transactionData.transactionCurrency + " " + props.transactionData.transactionAmountdata}</div>
                                        </div>

                                        <div className={styles.summaryRow}>
                                            <div className={styles.summaryLabel}>Total number of Invoices to be connected</div>
                                            <div className={styles.summaryValue}>{selectedCount}</div>
                                        </div>

                                        <div className={styles.summaryRow}>
                                            <div className={styles.summaryLabel}>Total selected invoice amount</div>
                                            <div className={styles.summaryValue}>{props.transactionData.transactionCurrency + " " + totalSelectedAmount}</div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>



                            <Grid container spacing={1.9} className='grid_bill_container'>
                                <Grid item md={12} xs={12}>
                                    <Paper sx={{ width: '100%', mb: 2, marginTop: "20px", boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                                        <TableContainer>
                                            <Table
                                                className='contenar_tebal_liner'
                                                aria-labelledby="tableTitle"
                                                size={dense ? 'small' : 'medium'}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                icon={<img src="/Checkbox.svg" />}
                                                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                indeterminate={selected.length > 0 && selected.length < selectData.length}
                                                                checked={selectData.length > 0 && selected.length === selectData.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{
                                                                    'aria-label': 'select all desserts',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {headCells.map((headCell) => (
                                                            <TableCell>
                                                                {headCell.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {datatabal && datatabal.length > 0 ?
                                                        datatabal.map((data: any, index: any) => {
                                                            const isItemSelected = isSelected(data.id);
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <TableRow
                                                                    role="checkbox"
                                                                    aria-checked={isItemSelected}
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    selected={isItemSelected}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                                        <Checkbox
                                                                            icon={<img src="/Checkbox.svg" />}
                                                                            disabled={data.status == "paid" ? true : false}
                                                                            checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                            onClick={(event) => handleClick(data.id, data.monite_invoice_id, data.total)}
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            inputProps={{
                                                                                'aria-labelledby': labelId,
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "0px", borderBottom: "0px solid" }}>
                                                                        {data?.customer?.name ? data?.customer?.name : "-"}
                                                                    </TableCell>
                                                                    <TableCell style={{ borderBottom: "0px solid" }}>
                                                                        {data.invoice_no}

                                                                    </TableCell>
                                                                    <TableCell style={{ borderBottom: "0px solid" }}>
                                                                        <Box className={styles.imgContean}>
                                                                            <Typography>{moment(data.created_at).format('DD-MMM-YYYY')}</Typography>
                                                                        </Box>
                                                                    </TableCell>

                                                                    <TableCell style={{ borderBottom: "0px solid" }}
                                                                    >
                                                                        {data.currency + " " + data.total}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }) :

                                                        <TableRow>
                                                            <TableCell colSpan={7} style={{ borderBottom: "0px solid" }}>
                                                                <TebalNoData text={'No Transaction found'} />
                                                            </TableCell>
                                                        </TableRow>}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>

                                    {
                                        totalSelectedAmount > props.transactionData.transactionAmountdata ?
                                            <p className={styles.limitExceed}>
                                                The total payin amount has exceeded the transaction amount. Please adjust your selections so the total pay-ins do not exceed the transaction amount
                                            </p>
                                            : ""
                                    }

                                    <div style={{ paddingTop: '10px', justifyContent: "space-between" }} className="addnewbtnclass">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="inpagenum">
                                                <p>
                                                    {remove - 9}-{remove}
                                                </p>
                                            </div>
                                            <div className="btnmaindivin">
                                                {pageneshn == 1 ? (
                                                    <button disabled className="nextntmistish">
                                                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="nextntmistish"
                                                        onClick={() => {
                                                            setPagelist(pageneshn - 1);
                                                            setRemovedata(remove - 10);
                                                            getBalanceList(pageneshn - 1)
                                                        }}
                                                    >
                                                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                )}
                                                {remove >= totalCount ? (
                                                    <button disabled className="nextntmistish">
                                                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="nextntmistish"
                                                        onClick={() => {
                                                            setPagelist(pageneshn + 1);
                                                            setRemovedata(remove + 10);
                                                            getBalanceList(pageneshn + 1)
                                                        }}
                                                    >
                                                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.buttinsDiv}>
                                            <Button
                                                onClick={() => handleLinkTransaction("cancel")}
                                                className={styles.fast_button_data}>
                                                <Typography>Cancel</Typography>
                                            </Button>
                                            <Button
                                                className={styles.two_button_invoice}
                                                disabled={selected.length > 0 && totalSelectedAmount <= props.transactionData.transactionAmountdata ? false : true}
                                                onClick={() => {
                                                    handleLinkTransaction("submit");
                                                }}
                                            >

                                                <Typography>Link to an Invoice
                                                    {loader == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                </Typography>
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </>

                    }>
                </ListSkeleton>
            </ThemeProvider>
        </>
    )
}
export default TransactinTable;
